<template>
  <div class="card  calendar-day-header">
    <div class="card-header">Bulk Buttons</div>
    <div class="list-group list-group-flush">
      <div v-for="(course, id) in courses" class="list-group-item" :key="id">
        <div v-if="course.hourly_fee === 0">
          <app-standard-buttons
            :course="course"
            :bulk="true"
          ></app-standard-buttons>
        </div>
        <div v-else>
          <app-hourly-buttons
            :course="course"
            :bulk="true"
          ></app-hourly-buttons>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { courseMethods } from '@/components/mixins/course'
import HourlyButtons from '@/components/shared/HourlyButtons'
import StandardButtons from '@/components/shared/StandardButtons'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'BulkEnroll',
  mixins: [courseMethods],
  props: {
    days: { type: Object, required: false }
  },
  data () {
    return {
      program_slug: ''
    }
  },
  components: {
    appHourlyButtons: HourlyButtons,
    appStandardButtons: StandardButtons
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'isProgramManager',
      templateByStart: 'templateByStart',
      templateByEnd: 'templateByEnd',
      specialDayTemplates: 'specialDayTemplates'
    }),
    courses: function () {
      const days = this.days
      const dayArray = Object.entries(days)
      const courses = {}
      for (const day of dayArray) {
        const templates = this.specialTemplates(day.special_day)
        const templateArray = Object.entries(templates)
        for (const template of templateArray) {
          courses[template.id] = template
        }
      }
      return courses
    }
  },
  methods: {
    specialTemplates: function (specialDay) {
      return this.specialDayTemplates(specialDay)
    },
    dayType: function (specialDay) {
      if (typeof specialDay === 'undefined') {
        return 'Regular'
      } else {
        return specialDay.name
      }
    },
    dayClass: function (specialDay) {
      if (typeof specialDay === 'undefined') {
        return 'regular'
      } else {
        return specialDay.type
      }
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}
.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>

import { mapGetters, mapMutations } from 'vuex'
import { SET_STUDENT_T_SHIRT_SIZE } from '@/constants/mutation-types'
import isEmpty from 'lodash/isEmpty'

export const tShirtMixin = {
  computed: {
    ...mapGetters(
      {
        getTShirtSize: 'tShirtSize'
      }
    )
  },
  methods: {

    ...mapMutations({
      setTShirtSize: SET_STUDENT_T_SHIRT_SIZE
    }),
    getSize (studentId) {
      const size = this.getTShirtSize({
        student_id: studentId
      })
      if (isEmpty(size)) {
        return ''
      }
      return size
    },
    setSize (studentId, size) {
      this.setTShirtSize({
        student_id: studentId,
        size: size
      })
    },
    hasSize (studentId) {
      const size = this.getSize(studentId)
      return !isEmpty(size)
    }
  }
}

<template>
  <div v-if="isStudentEnrolledEmit" :key="student.id" class="p-1">
    <cs-button
        v-if="allowChange || isManager"
        :loading="$wait.is(waitDelete)"
        @handle-click="dropEnrolled()"
        class="btn btn-danger btn-block "
        title="Drop selected time"
        icon-left="drop"
    >Drop {{ student.first }}
    </cs-button>
      <button v-else class="btn btn-outline-info btn-block disabled" disabled>
          {{ student.first }} enrolled
      </button>
  </div>
  <cs-button
      v-else-if="inCart"
      :key="student.id"
      @handle-click="removeCourseFromCart()"
      type="warning"
      icon-left="drop-cart"
      custom-class="btn-block"
      :loading="$wait.is(waitDelete)"
  >
    {{ student.first }}
  </cs-button>
  <span v-else-if="(course.public || isManager) && !blockEnrollmentWithUnpaid"
        :key="student.id">
    <cs-button
        v-if="shouldEnroll || isManager"
        @handle-click="doEnrollWithBalance"
        type="primary"
        icon-left="enroll"
        :loading="$wait.is(waitAdd)"
        custom-class="btn-block"
        :title="isManager?'Enroll directly as Manager':'Since you have a registration balance greater then the course fees you can enroll directly'"
    >
      Enroll {{ student.first }}
    </cs-button>
    <cs-button
        v-else
        @handle-click="addCourseToCart()"
        type="success"
        icon-left="add-cart"
        custom-class="btn-block"
        :loading="$wait.is(waitAdd)"
    >
      Add {{ student.first }} to cart
    </cs-button>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'
import { isEmpty, isNull } from 'lodash/core'
import moment from 'moment'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'DayStandardButton',
  mixins: [courseMethods, calendarMethods],
  props: {
    student: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    calendar: {
      type: Object,
      required: false
    },
    day: {
      type: Object,
      required: false
    },
    enrolled: {
      type: Object,
      required: false
    },
    isStudentEnrolled: {
      type: Boolean,
      required: true
    },
    /**
     * if it should enroll directly or add to cart
     */
    shouldEnroll: {
      type: Boolean,
      required: false,
      default: false
    },
    isFull: {
      type: Boolean,
      required: false,
      default: false
    },
    signupClosed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      studentSelections: []
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    isStudentEnrolledEmit () {
      if (this.isStudentEnrolled) {
        if (!this.course.public) {
          this.$emit('privateEnrolled')
        }
        return true
      } else {
        return false
      }
    },
    isDropped () {
      const enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && enrolled.dropped) {
        return true
      } else {
        return false
      }
    },
    waitAdd () {
      let result = `cart.add.${this.course.id}.student.${this.student.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.day.date
      }
      return result
    },
    waitDelete () {
      let result = `cart.delete.${this.course.id}.student.${this.student.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.day.date
      }
      return result
    },
    canEnrollWithBalance () {
      return this.registrationBalance < 0 && this.registrationBalance + this.fees <= 0
    },
    registrationBalance () {
      return this.registration.balance
    },
    fees () {
      return this.course.fee + this.course.materialFee
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    doEnrollWithBalance () {
      this.$wait.start(this.waitAdd)
      this.enrollStudentInDay(this.student, this.day.date, this.course, null, null)
        .then(data => {
          console.log('didEnrollWithBalance.data', data)
          this.$wait.end(this.waitAdd)
          this.$emit('enrolled-course',
            {
              course: this.course,
              student: this.student,
              day: this.day
            })
        })
        .catch((error) => {
          console.error('didEnrollWithBalance.data error', error)
          this.$wait.end(this.waitAdd)
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: this.student.name + ' problem',
            text:
                  this.student.name +
                  ' problem adding student to cart for day.',
            duration: 2000,
            speed: 1000
          })
        })
    },
    addCourseToCart () {
      this.disabled = true
      if (isNull(this.course.session_id)) {
        if (!isNull(this.course.site_calendar_id)) {
          this.$wait.start(this.waitAdd)
          this.addDayToCart(
            this.student,
            this.day.date,
            this.course,
            null,
            null
          )
            .then(data => {
              if (!isEmpty(data.students)) {
                window.cfEventBus.$emit('update-cart', this.program)
                const students = data.students
                for (const studentId in students) {
                  const student = students[studentId]
                  var message = ''
                  if (!isEmpty(student.courses)) {
                    const courses = student.courses
                    var courseNames = []
                    for (const courseId in courses) {
                      const course = courses[courseId]
                      const courseDay = moment(course.day).format('M/D')
                      courseNames.push(course.name + ' ' + courseDay)
                    }
                    message = courseNames.join(',')
                  }
                  this.$emit('added-to-cart',
                    {
                      course: this.course,
                      student: this.student,
                      day: this.day
                    })
                  this.$notify({
                    group: 'enrollment',
                    type: 'success',
                    title: student.first + ' added to cart',
                    text: message,
                    duration: 2000,
                    speed: 1000
                  })
                }
              }
              this.$wait.end(this.waitAdd)
            })
            .catch(() => {
              this.$wait.end(this.waitAdd)
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.student.name + ' problem',
                text:
                      this.student.name +
                      ' problem adding student to cart.',
                duration: 2000,
                speed: 1000
              })
            })
        }
      } else {
        this.addToCart(this.student)
          .then(() => {
          })
          .catch(() => {
            this.disabled = false
          })
      }
    },
    removeCourseFromCart () {
      console.warn('cart', this.cart, this.course)
      if (!isNull(this.course.site_calendar_id)) {
        this.$wait.start(this.waitDelete)
        this.removeDayFromCart(
          this.student,
          {
            id: this.cart.course_id,
            name: this.cart.course
          },
          this.calendar,
          this.registration
        )
          .then(data => {
            if (!isEmpty(data.students)) {
              window.cfEventBus.$emit('update-cart', this.program)
              const students = data.students
              for (const studentId in students) {
                const student = students[studentId]
                var message = ''
                if (!isEmpty(student.courses)) {
                  const courses = student.courses
                  var courseNames = []
                  for (const courseId in courses) {
                    const course = courses[courseId]
                    const courseDay = moment(course.day).format('M/D')
                    courseNames.push(course.name + ' ' + courseDay)
                  }
                  message = courseNames.join(',')
                }
                this.$notify({
                  group: 'enrollment',
                  type: 'success',
                  title: student.first + ' added to cart',
                  text: message,
                  duration: 2000,
                  speed: 1000
                })
              }
            }
            this.$wait.end(this.waitDelete)
          })
          .catch(() => {
            this.$wait.end(this.waitDelete)
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.student.name + ' problem',
              text:
                    this.student.name +
                    ' problem adding student to cart for day.',
              duration: 2000,
              speed: 1000
            })
          })
      } else {
        this.addToCart(this.student)
          .then(() => {
          })
          .catch(() => {
            this.disabled = false
          })
      }
    },
    dropEnrolled () {
      this.$wait.start(this.waitDelete)
      var enrolled = this.enrolled_course
      this.disabled = true
      this.drop(enrolled, this.course, this.student)
        .then((result) => {
          console.log('dropEnrolled', result)
          this.$wait.end(this.waitDelete)
        })
        .catch((error) => {
          console.error('dropEnrolled', error)
          this.$wait.end(this.waitDelete)
        })
    }
  }

}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>

<template>
  <div class="list-group list-group-flush">
    <div v-for="student in students" class="list-group-item" :key="student.id">
      <strong>{{ student.first }}: </strong>
      <span v-for="course in student.courses" :key="course.id">
        <span v-html="course.name"></span> from
        {{ timeRange(course.startTime, course.endTime) }} on {{ days(course) }}
      </span>
    </div>
  </div>
</template>

<script>

import { timeRange } from '@/utilities/dateFormatters'

export default {
  name: 'OverlappedEnrolledCourses',
  props: {
    students: {
      type: [Array, Object],
      required: true
    }
  },
  methods: {
    timeRange,
    days (course) {
      return course.enrolled_course.days
    }
  }
}
</script>

<style scoped></style>

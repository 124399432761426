var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.status === 'loading')?_c('div',[_c('div',{key:_vm.loading,staticClass:"alert alert-info"},[_c('h2',[_c('cs-loading',{attrs:{"theme":"info"}},[_vm._v("\n            Loading "+_vm._s(_vm.titleCase(_vm.plural(_vm.program.calendar_label, 2)))+"\n          ")])],1)])]):(_vm.status === 'error')?_c('div',[_c('div',{key:_vm.error,staticClass:"alert alert-danger"},[_vm._v("\n        Error:\n        "),_c('json-view',{attrs:{"data":_vm.error}})],1)]):_c('div',{key:"loaded",attrs:{"id":"calendars"}},[(_vm.calendars.length > 0)?_c('div',{key:"calendars",staticClass:"card w-100"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v(_vm._s(_vm.titleCase(_vm.plural(_vm.program.calendar_label, _vm.calendars.length)))+" for "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.program.name)}})])]),_vm._v(" "),_c('div',{staticClass:"card-body p-0 p-lg-2"},[_c('nav',{staticClass:"nav nav-pills flex-column flex-sm-row"},_vm._l((_vm.calendars),function(calendar){return _c('button',{key:calendar.id,staticClass:"flex-sm-fill text-sm-center nav-link",class:{
                'bg-success':
                  (_vm.selectedCalendar == null ||
                    calendar.id === _vm.selectedCalendar.id) &&
                  calendar.registration_open,
                'bg-success-light':
                  calendar.registrationOpen &&
                  !(
                    _vm.selectedCalendar == null ||
                    calendar.id === _vm.selectedCalendar.id
                  ),
                'bg-info':
                  !calendar.registrationOpen &&
                  (_vm.selectedCalendar == null ||
                    calendar.id === _vm.selectedCalendar.id),
                'bg-info-light':
                  !calendar.registration_open &&
                  !(
                    _vm.selectedCalendar == null ||
                    calendar.id === _vm.selectedCalendar.id
                  )
              },on:{"click":function($event){$event.preventDefault();return _vm.selectCalendar(calendar)}}},[_c('span',{domProps:{"innerHTML":_vm._s(calendar.name)}}),_c('br'),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.dateRange(calendar.start_date, calendar.end_date)))])])}),0),_vm._v(" "),_c('transition',[(_vm.loading)?_c('div',{staticClass:"card bg-info-light"},[_c('h3',{staticClass:"card-header"},[_c('cs-loading',{attrs:{"theme":"info"}},[_vm._v("\n                  "+_vm._s(_vm.loadingMessage)+"\n                ")])],1)]):_vm._e()]),_vm._v(" "),_c('transition',[(_vm.selectedCalendar !== null && _vm.selectedCalendar.id !== 0)?_c('app-calendar',{attrs:{"id":"calendar","loggedIn":_vm.loggedIn},on:{"load-month":_vm.loadMonth}}):_vm._e()],1)],1)]):_c('div',{staticClass:"card w-100 border-warning"},[_c('div',{staticClass:"card-header bg-warning"},[_c('h4',[_vm._v("No calendars available for "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.program.name)}})])]),_vm._v(" "),_c('div',{staticClass:"card-body bg-warning-light"},[_c('p',{staticClass:"lead"},[_vm._v("\n            Please check with the "+_vm._s(_vm.program.admin_name)+" for more\n            information\n            "),_c('a',{staticClass:"btn btn-info",attrs:{"href":'mailto:' + _vm.program.admin_email}},[_c('font-awesome-icon',{attrs:{"icon":"envelope"}}),_vm._v("\n              "+_vm._s(_vm.program.admin_name))],1),_vm._v("\n            "+_vm._s(_vm._f("phone")(_vm.program.phone))+" for more information\n          ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
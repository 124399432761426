<template>
  <div
    class="card"
    :class="{
      'border-warning': isManager && !course.public
    }"
  >
    <h4 class="card-header">
      <span v-html="course.name" /><span
        v-if="isManager && !course.public"
        class="badge badge-warning"
        >Private</span
      >
    </h4>
    <transition name="fade" key="course.id">
      <div v-if="hasInfo" class="card-body">
        <span v-html="info" />
        <button
          v-if="hasMore"
          v-on:click="showMore = !showMore"
          class="btn btn-info"
        >
          More...
        </button>
        <div v-if="showMore"><span v-html="course.description" /></div>
      </div>
    </transition>
    <div class="list-group list-group-flush">
      <div class="list-group-item">
        <strong>Grades:</strong> {{ course.grade_list }}
      </div>
      <div class="list-group-item">
        <strong>Times:</strong>
        {{ timeString(course.startTime) }} -
        {{ timeString(course.endTime) }}
      </div>
      <div v-if="course.fee > 0" class="list-group-item">
        <strong>Fee:</strong> {{ course.fee | currency }}
      </div>
      <div v-if="course.hourly_fee > 0" class="list-group-item">
        <strong>Hourly Fee:</strong>
        {{ course.hourly_fee | currency }} / hour
      </div>
    </div>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { timeString } from '@/utilities/dateFormatters'
export default {
  name: 'CourseSummary',
  props: {
    course: {
      type: Object,
      required: true
    },
    isManager: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showBrief: false,
      showMore: false
    }
  },
  computed: {
    hasInfo: function () {
      return !isEmpty(this.course.brief) || !isEmpty(this.course.description)
    },
    hasMore: function () {
      if (this.hasInfo && this.info !== this.course.description) {
        return true
      }
      return false
    },
    info: function () {
      if (!isNil(this.course)) {
        if (!isEmpty(this.course.brief)) {
          return this.course.brief
        } else if (!isEmpty(this.course.description)) {
          return this.course.description
        }
      }
      return ''
    }
  },
  methods: {
    timeString
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <month-navigation
          :month="month"
          :calendar="calendar"
          :year="year"
          :weeks="weeks"
          v-on:load-month="loadMonth"
      />
      <month-notice
          :calendar="calendar"
          :month="month"
          :year="year"
          :students="students"
          :weeks="weeks"
          :has-credit="hasCredit"
          :enable-enroll-many-days.sync="enableEnrollManyDays"
          :use-credit-for-enrollment.sync="useCreditForEnrollment"
      />
    </div>
    <div class="card-body" :key="month">
      <div
          v-if="!isManager && pendingEnrollments"
          v-bind:class="{
          'alert-danger': hasBalance,
          'alert-warning': !hasBalance
        }"
          class="alert"
      >
        <p v-if="hasBalance" class="lead">
          Newly scheduled days are not enrolled until your payment is complete
        </p>
        <p v-else class="lead">
          You have to confirm enrollment on the registration page for the new
          days to be enrolled.
        </p>
      </div>
      <div class="row dow-header w-100">
        <div class="col-md-1">
          &nbsp; &nbsp;
        </div>
        <div class="col-md-2">
          <h5 class="text-center">Mon</h5>
        </div>
        <div class="col-md-2">
          <h5 class="text-center">Tues</h5>
        </div>
        <div class="col-md-2">
          <h5 class="text-center">Wed</h5>
        </div>
        <div class="col-md-2">
          <h5 class="text-center">Thur</h5>
        </div>
        <div class="col-md-2">
          <h5 class="text-center">Fri</h5>
        </div>
      </div>
    </div>
    <template v-for="(days, dow) in weeks">
      <week
          :days="days"
          :weekNumber="dow"
          :year="year"
          :month="month"
          :calendar="calendar"
          :students="students"
          :prev-month-week="prevMonthWeek"
          :next-month-week="nextMonthWeek"
          :showBulkActions="showBulkActions"
          :can-use-credit-for-enrollment="canUseCreditForEnrollment"
          :key="month + '-' + dow"
          @added-to-cart="addedToCart"
          @enrolled-course="enrolledCourse"
      />
    </template>
    <div
        v-if="!isManager && pendingEnrollments"
        v-bind:class="{
        'bg-warning-light': hasBalance,
        'bg-danger-light': !hasBalance
      }"
        class="bg-warning-light text-warning card-body"
    >
      <p v-if="hasBalance" class="lead">
        Newly scheduled days are not enrolled until your payment is complete
      </p>
      <p v-else class="lead">
        You have to confirm enrollment on the registration page for the new days
        to be enrolled.
      </p>
    </div>
    <div class="card-footer text-center">
      <month-navigation
          :month="month"
          :calendar="calendar"
          :year="year"
          :weeks="weeks"
          :on-top="false"
          v-on:load-month="loadMonth"
      />
    </div>
    <v-dialog v-model="showEnrollManyDays" bg-transition="fade"
              :classes="{
     content: 'bg-danger m-0 p-0 w-'
      }">
      <enroll-many-days
          :course="enrolledPayload.course"
          :date="enrolledPayload.date"
          :start="enrolledPayload.start"
          :end="enrolledPayload.end"
          :student="enrolledPayload.student"
          :students="students"
          :calendar="calendar"
          @close="showEnrollManyDays = false"/>
    </v-dialog>
    <v-dialog v-model="showOutOfCreditDialog" bg-transition="fade"
              :classes="{
     content: 'bg-danger m-0 p-0 w-75'
      }">
      <div class="card border-warning">
        <h2 class="card-header bg-warning text-dark text-center">You are out of credit <small>for signing up</small></h2>
        <div class="card-body">
          <p class="lead">You are out of credit so all further scheduling will be added to the <strong>Cart</strong> and will have to be paid in order to be enrolled in the days.</p>
        </div>
        <div class="card-footer text-right">
          <cs-button @handle-click="showOutOfCreditDialog = false" type="danger" icon-left="cancel">Close</cs-button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { urlBuilder } from '@/utilities/http-common'
import isNull from 'lodash/isNull'
import { mapGetters } from 'vuex'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Week from './Week'
import MonthNavigation from '@/components/calendars_sessions/calendars/MonthNavigation'
import MonthNotice from '@/components/calendars_sessions/calendars/MonthNotice'
import EnrollManyDays from '@/components/calendars_sessions/calendars/EnrollManyDays'
import { calendarMixin } from '@/components/mixins/calendarMixin'
import { VDialog } from 'vuetensils/src/components'
import { toBoolean } from '@/utilities/cs-utilities'

const moment = extendMoment(Moment)

export default {
  name: 'Month',
  mixins: [calendarMixin],
  components: {
    Week: Week,
    MonthNavigation,
    MonthNotice,
    VDialog,
    EnrollManyDays
  },
  props: {
    year: {
      type: [Number, String],
      required: true
    },
    calendar: {
      type: Object,
      required: true
    },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      startTime: null,
      endTime: null,
      startTimes: [{ time: '6:30 AM' }, { time: '7:00 AM' }],
      endTimes: [
        { time: '11:30 AM' },
        { time: '12:00 PM' },
        { time: '1:00 PM' },
        { time: '2:00 PM' },
        { time: '3:00 PM' },
        { time: '4:00 PM' },
        { time: '5:00 PM' },
        { time: '6:00 PM' }
      ],
      showEnrollManyDays: false,
      enableEnrollManyDays: false,
      useCreditForEnrollment: false,
      enrolledPayload: {},
      shouldCheckUsedAllCredit: false,
      showOutOfCreditDialog: false
    }
  },
  mounted () {
    if (localStorage.enableEnrollManyDays) {
      this.enableEnrollManyDays = toBoolean(localStorage.enableEnrollManyDays)
    }
    if (localStorage.useCreditForEnrollment) {
      this.useCreditForEnrollment = toBoolean(localStorage.useCreditForEnrollment)
    }
  },
  watch: {
    enableEnrollManyDays (newValue) {
      localStorage.enableEnrollManyDays = newValue
    },
    useCreditForEnrollment (newValue) {
      localStorage.useCreditForEnrollment = newValue
    },
    'registration.amountDue' (newDue) {
      if (this.shouldCheckUsedAllCredit && !this.hasCredit && this.useCreditForEnrollment && !this.isManager) {
        this.shouldCheckUsedAllCredit = false
        this.showOutOfCreditDialog = true
      }
    }
  },
  computed: {
    ...mapGetters({
      registration: 'registration',
      calendars: 'calendars',
      program: 'program',
      loggedIn: 'loggedIn',
      isManager: 'gteManager'
    }),
    canUseCreditForEnrollment () {
      return this.hasCredit && this.useCreditForEnrollment
    },
    showBulkActions () {
      if (this.isManager) {
        // check to see if features that might enable bulkActions are active
        if (this.calendar.weekly_late_fee > 0) {
          return true
        }
      }
      return false
    },
    weeks () {
      return this.calendar.days[this.year][this.month]
    },
    prevMonthWeek () {
      let prevMonth = parseInt(this.month) - 1
      let prevYear = this.year
      if (prevMonth <= 0) {
        prevMonth = 12
        prevYear = parseInt(this.year) - 1
      }
      let prevWeeks = {}
      if (prevYear in this.calendar.days &&
          prevMonth in this.calendar.days[prevYear] &&
      !Array.isArray(this.calendar.days[prevYear][prevMonth])) {
        prevWeeks = this.calendar.days[prevYear][prevMonth]
      }
      return prevWeeks
    },
    nextMonthWeek () {
      const days = this.calendar.days
      let nextMonth = parseInt(this.month) + 1
      let nextYear = this.year
      if (nextMonth > 12) {
        nextMonth = 1
        nextYear = parseInt(this.year) + 1
      }
      let nextWeeks = {}
      if (nextYear in days &&
          nextMonth in days[nextYear] &&
          !Array.isArray(this.calendar.days[nextYear][nextMonth])) {
        nextWeeks = this.calendar.days[nextYear][nextMonth]
      }
      return nextWeeks
    },
    month () {
      return parseInt(this.calendar.display_month)
    },
    hasNextMonth () {
      const end = moment(this.calendar.end_date)
      const weeks = this.weeks
      const first = Object.keys(weeks)
      const week = weeks[first[first.length - 1]]
      const last = Object.keys(week)
      const day = week[last[last.length - 1]]
      const nextMonth = moment(day.date)
        .add(1, 'months')
        .startOf('month')
      return end.isAfter(nextMonth)
    },
    hasPrevMonth () {
      const start = moment(this.calendar.start_date)
      const weeks = this.weeks
      const first = Object.keys(weeks)
      const week = weeks[first[0]]
      const day = week[Object.keys(week)[0]]
      const prevMonth = moment(day.date)
        .subtract(1, 'months')
        .endOf('month')
      return start.isBefore(prevMonth)
    },
    nextMonthName () {
      const name = moment().month(this.nextMonth - 1)
      return name.format('MMMM')
    },
    prevMonthName () {
      const name = moment().month(this.prevMonth - 1)
      return name.format('MMMM')
    },
    nextMonth () {
      const monthNumber = parseInt(this.month)
      if (monthNumber === 12) {
        return 1
      } else {
        return monthNumber + 1
      }
    },
    prevMonth () {
      const monthNumber = parseInt(this.month)
      if (monthNumber === 1) {
        return 12
      } else {
        return monthNumber - 1
      }
    },
    hasBalance: function () {
      if (!isNull(this.registration)) {
        return this.registration.balance > 0
      }
      return false
    },
    balance: function () {
      if (this.hasBalance) {
        return this.registration.balance
      }
      return 0
    },
    hasCredit: function () {
      if (!isNull(this.registration)) {
        return this.registration.balance < 0
      }
      return false
    },
    credit: function () {
      if (this.hasCredit) {
        return -1 * this.registration.balance
      }
      return 0
    },
    pendingEnrollments: function () {
      if (!isNull(this.registration)) {
        return this.registration.pending_enrollments.length > 0
      }
      return false
    }
  },
  methods: {
    addedToCart (payload) {
      console.log('Month.addedToCart', payload)
      this.shouldCheckUsedAllCredit = false
      this.enrolledMany(payload)
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      console.log('Month.enrolledCourse', payload)
      this.shouldCheckUsedAllCredit = true
      this.enrolledMany(payload)
      this.$emit('enrolled-course', payload)
    },
    enrolledMany: function (payload) {
      console.log('Month.enrolledMany', payload)
      if ('date' in payload || 'day' in payload) {
        const date = moment('date' in payload ? payload.date : payload.day.date)
        if ('day' in payload && !('date' in payload)) {
          payload.date = payload.day.date
        }
        const course = payload.course
        let enrollableDayCount = 0
        this.students.forEach(student => {
          const count = this.daysCanEnroll(date, course, student).length
          enrollableDayCount = enrollableDayCount + count
        })
        console.warn('enrollableDayCount', enrollableDayCount)
        if (this.enableEnrollManyDays && enrollableDayCount >= 4 /* && this.program.slug !== 'vvelc' */) {
          this.showEnrollManyDays = true
          this.enrolledPayload = payload
        }
      }
    },
    payRegistration (program, event) {
      event.target.disabled = true
      const returnTo = window.location.pathname
      const registration = this.registration
      window.location.href = urlBuilder('/registrations/view', {
        registration_id: registration.id,
        return_to: returnTo,
        scroll_to: 'pay'
      })
    },
    loadMonth: function (month) {
      this.$emit('load-month', month)
    }
  }
}
</script>

<style scoped></style>

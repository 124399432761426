<template>
  <div>
    <nav v-if="onTop" class="nav nav-pills flex-column flex-sm-row">
      <button
          v-for="goToMonth in months"
          :key="goToMonth.index"
          class="flex-sm-fill text-sm-center nav-link"
          v-on:click.prevent="$emit('load-month', goToMonth.index)"
          :class="{
          disabled: parseInt(goToMonth.index) === parseInt(month)
        }"
          :disabled="goToMonth.index === month"
          :aria-disabled="goToMonth.index === month ? 'true' : 'false'"
      >
        {{ goToMonth.display }}
      </button>
    </nav>
    <h2 class="text-center">
      <button
          v-if="hasPrevMonth"
          @click="$emit('load-month', prevMonth)"
          class="btn btn-primary float-left"
      >
        <font-awesome-icon icon="chevron-left"></font-awesome-icon>&nbsp;{{
          prevMonthName
        }}
      </button>
      {{ calendarName }}
      <button
          v-if="balance > 0"
          v-on:click.prevent="payRegistration( $event)"
          v-tooltip.top="{
          content: 'Pending Days are not enrolled until payment is made'
        }"
          class="btn btn-success btn-truncate-text"
      >
        <font-awesome-icon icon="credit-card-front"></font-awesome-icon>
        Pay {{ currency(balance) }}
      </button>
      <button
          v-else-if="balance > 0 && pendingEnrollments"
          v-on:click.prevent="payRegistration( $event)"
          v-tooltip.top="{
          content: 'Pending Days are not enrolled until payment is made'
        }"
          class="btn btn-danger btn-truncate-text"
      >
        <font-awesome-icon icon="credit-card-front"></font-awesome-icon>
        Pay {{ currency(balance) }} and Enroll
      </button>
      <button
          v-else-if="pendingEnrollments"
          v-on:click.prevent="payRegistration( $event)"
          v-tooltip.top="{
          content:
            'Pending Days are not enrolled until confirmed on registration'
        }"
          class="btn btn-warning btn-truncate-text"
      >
        <font-awesome-icon icon="credit-card-front"></font-awesome-icon>
        Confirm Enrollment
      </button>
      <button
          v-else
          v-on:click.prevent="openRegistration( $event)"
          v-tooltip.top="{
          content:
            'go back to registration'
        }"
          class="btn btn-info btn-truncate-text"
      >
        <font-awesome-icon icon="credit-card-front"></font-awesome-icon>
        Registration {{ currency(registration.balance) }}
      </button>
      <button
          v-if="hasNextMonth"
          @click="$emit('load-month', nextMonth)"
          class="btn btn-primary float-right"
      >
        {{ nextMonthName }}&nbsp;<font-awesome-icon
          icon="chevron-right"
      ></font-awesome-icon>
      </button>
    </h2>
    <div  v-if="isManager"
        class="card bg-manager-light border-manager">
      <h5 class="card-header bg-manager text-light">Manager Options</h5>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" v-model="canOverEnroll" type="checkbox" value="" id="canOverEnrollCheck">
              <label class="form-check-label" for="canOverEnrollCheck">
                Can Over Enroll Days
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>

    <nav v-if="!onTop" class="nav nav-pills flex-column flex-sm-row">
      <button
          v-for="goToMonth in months"
          :key="goToMonth.index"
          class="flex-sm-fill text-sm-center nav-link"
          v-on:click.prevent="$emit('load-month', goToMonth.index)"
          :class="{
          disabled: parseInt(goToMonth.index) === parseInt(month)
        }"
          :disabled="goToMonth.index === month"
          :aria-disabled="goToMonth.index === month"
      >
        {{ goToMonth.display }}
      </button>
    </nav>
  </div>
</template>
<script>
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import isNull from 'lodash/isNull'
import { mapGetters, mapMutations } from 'vuex'
import { urlBuilder } from '@/utilities/http-common'
import { currency } from '@/utilities/stringFormatters'
import { dateString } from '@/utilities/dateFormatters'
import parseISO from 'date-fns/parseISO'
import getMonth from 'date-fns/getMonth'
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval'
const moment = extendMoment(Moment)

export default {
  name: 'MonthNavigation',
  props: {
    month: {
      type: [Number, String],
      default: 0
    },
    year: {
      type: [Number, String],
      required: true
    },
    calendar: {
      type: Object,
      required: true
    },
    weeks: {
      type: [Array, Object],
      required: true
    },
    onTop: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      registration: 'registration',
      allowOverEnroll: 'allowOverEnroll',
      isManager: 'isProgramManager'
    }),
    canOverEnroll: {
      get () {
        return this.allowOverEnroll
      },
      set (value) {
        this.setAllowOverEnroll(value)
      }
    },
    calendarName () {
      const now = new Date()
      // php uses 1 indexed months
      now.setFullYear(this.year, this.month - 1)
      return dateString(now, 'MMMM yyyy')
    },
    hasNextMonth () {
      const end = moment(this.calendar.end_date)
      const weeks = this.weeks
      const first = Object.keys(weeks)
      const week = weeks[first[first.length - 1]]
      const last = Object.keys(week)
      const day = week[last[last.length - 1]]
      const nextMonth = moment(day.date)
        .add(1, 'months')
        .startOf('month')
      return end.isAfter(nextMonth)
    },
    hasPrevMonth () {
      const start = moment(this.calendar.start_date)
      const weeks = this.weeks
      const first = Object.keys(weeks)
      const week = weeks[first[0]]
      const day = week[Object.keys(week)[0]]
      const prevMonth = moment(day.date)
        .subtract(1, 'months')
        .endOf('month')
      return start.isBefore(prevMonth)
    },
    nextMonthName () {
      const name = moment().month(this.nextMonth - 1)
      return name.format('MMMM')
    },
    prevMonthName () {
      const name = moment().month(this.prevMonth - 1)
      return name.format('MMMM')
    },
    nextMonth () {
      const monthNumber = parseInt(this.month)
      if (monthNumber === 12) {
        return 1
      } else {
        return monthNumber + 1
      }
    },
    prevMonth () {
      const monthNumber = parseInt(this.month)
      if (monthNumber === 1) {
        return 12
      } else {
        return monthNumber - 1
      }
    },
    hasBalance: function () {
      if (!isNull(this.registration)) {
        return this.registration.balance > 0
      }
      return false
    },
    balance: function () {
      if (this.hasBalance) {
        return this.registration.balance
      }
      return 0
    },
    pendingEnrollments: function () {
      if (!isNull(this.registration)) {
        return this.registration.pending_enrollments.length > 0
      }
      return false
    },
    months () {
      const start = parseISO(this.calendar.start_date)
      const end = parseISO(this.calendar.end_date)
      const arrayOfMonths = eachMonthOfInterval({
        start: start,
        end: end
      })
      return arrayOfMonths.map(month => {
        return {
          index: getMonth(month) + 1, // returns a 0 based index not the month number
          display: dateString(month, 'MMM'),
          month: month
        }
      })
    }
  },
  methods: {
    ...mapMutations({
      setAllowOverEnroll: 'allowOverEnroll' // map `this.add()` to `this.$store.commit('increment')`
    }),
    currency,
    dateString,
    payRegistration (event) {
      event.target.disabled = true
      const returnTo = window.location.pathname
      const registration = this.registration
      const params = {
        registration_id: registration.id,
        return_to: returnTo,
        scroll_to: 'pay'
      }
      window.location.href = urlBuilder('/registrations/view', params)
    },
    openRegistration (event) {
      event.target.disabled = true
      const returnTo = window.location.pathname
      const registration = this.registration
      const params = {
        registration_id: registration.id,
        return_to: returnTo
      }
      window.location.href = urlBuilder('/registrations/view', params)
    }
  }
}
</script>

<style scoped></style>

export const dayOfWeekInBitmask = (dow, bitmask) => {
  let bit = 0
  switch (dow) {
    case 1: // Monday
      bit = 1
      break
    case 2: // Tuesday
      bit = 2
      break
    case 3: // Wednesday
      bit = 4
      break
    case 4: // Thursday
      bit = 8
      break
    case 5: // Friday
      bit = 16
      break
    case 6: // Saturday
      bit = 32
      break
    case 7: // Sunday
      bit = 64
      break
    default:
      return false
  }
  const result = bitmask & bit
  return result === bit
}

<template>
  <div class="list-group list-group-flush">
    <div v-for="student in students" class="list-group-item" :key="student.id">
      <strong>{{ student.first }}: </strong>
      <span v-for="course in student.courses" :key="course.id">
        {{ course.paid_hours }} hours paid
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrePaidBilling',
  props: {
    students: {
      type: [Array, Object],
      required: true
    }
  }
}
</script>

<style scoped></style>

<template>
  <div class="card">
    <div class="card-header calendar-day-header text-muted">
      {{ dateString(day.date,'M/d eeeeee') }}
    </div>
    <div class="card-body">
      <p class="lead text-muted">{{ dateString(day.date) }}</p>
    </div>
  </div>
</template>

<script>

import { dateString } from '@/utilities/dateFormatters'

export default {
  name: 'OtherMonthDay',
  props: {
    day: {
      type: Object,
      required: true
    },
    month: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    date () {
      console.log('OtherMonthDay.date', this.day, this.day.date, typeof this.day.date)
      return this.day.date
    },
    typeDate () {
      return typeof this.date
    }
  },
  methods: {
    dateString
  }
}
</script>

<style scoped></style>

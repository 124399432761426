<template>
  <div v-if="enrolled.length > 0" class="list-group list-group-flush">
    <div
      v-for="course in enrolled"
      class="list-group-item list-group-item-info"
      :key="course.id"
    >
      <strong><span v-html="course.name"></span></strong>
      <p v-for="enrolled_course in course.students" :key="enrolled_course.id">
        {{ student(enrolled_course.student_id).first }}
        {{ times(enrolled_course, course) }}
      </p>
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
export default {
  name: 'EnrolledDaySummary',
  props: {
    courses: {
      type: Array,
      required: true
    },
    students: {
      type: Array,
      required: true
    }
  },
  computed: {
    enrolled () {
      const courses = this.courses
      if (!isNil(courses)) {
        const result = courses.filter(function (course) {
          const students = course.students
          if (!isEmpty(students)) {
            return true
          } else {
            return false
          }
        })
        return result
      }
      return []
    }
  },
  methods: {
    student (id) {
      if (this.students.length === 1) {
        return this.students[0]
      } else {
        return this.students.find(function (student) {
          return Number(student.id) === Number(id)
        })
      }
    },
    times (enrolledCourse, course) {
      let start = null
      let end = null
      if (!isEmpty(enrolledCourse.start)) {
        start = enrolledCourse.start
      }
      if (!isEmpty(enrolledCourse.end)) {
        end = enrolledCourse.end
      }
      if (!isNil(start) && !isNil(end)) {
        return moment(start).format('LT') + '-' + moment(end).format('LT')
      } else if (!isNil(start)) {
        return moment(start).format('LT')
      } else if (!isNil(end)) {
        return moment(end).format('LT')
      } else {
        start = course.startTime
        end = course.endTime
        return moment(start).format('LT') + '-' + moment(end).format('LT')
      }
    }
  }
}
</script>

<style scoped></style>

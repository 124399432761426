<template>
  <div>
    <div v-if="!isManager && hasCredit"
        class="alert alert-info">
      <h4 class="text-center">You have an available balance</h4>
      <div class="form-check" v-if="!isManager">
        <input v-model="useCreditForEnrollmentToggle"
               class="form-check-input"
               type="checkbox"
               id="useCreditForEnrollment"
               aria-describedby="useCreditForEnrollmentHelpBlock">
        <label class="form-check-label" for="useCreditForEnrollment">
          Use Available Credit for Enrolling
        </label>
        <small
            id="useCreditForEnrollmentHelpBlock"
            class="form-text text-muted">When you have a credit you can choose to directly use that credit to enroll
          days, if there is enough credit remaining for the maximum cost of that days options.</small>

      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 w-100">
      <div class="col mb-4">
        <div class="card bg-info-light border-info"
             @mouseenter="showOptionHelpInfo = true"
             @mouseleave="showOptionHelpInfo = false"
        >
          <div class="card-body">
            <div v-if="availableDays > 4" class="form-check">
              <input class="form-check-input" value=""
                     id="enableEnrollManyDays"
                     type="checkbox"
                     v-model="enableEnrollManyDaysToggle"
                     aria-describedby="enableEnrollManyDaysHelpBlock"
              >
              <label class="form-check-label" for="enableEnrollManyDays">
                Show option to enroll many days
              </label>
              <transition name="fade">
                <small v-show="showOptionHelpInfo" id="enableEnrollManyDaysHelpBlock" class="form-text text-muted">
                  After you select a time for a student in a day you will can sign up multiple
                  days/students for that same time, in days of the same type.
                </small>
              </transition>
            </div>
            <div class="form-check" v-if="!isManager">
              <input v-model="useCreditForEnrollmentToggle"
                     class="form-check-input"
                     type="checkbox"
                     id="useCreditForEnrollment"
                     aria-describedby="useCreditForEnrollmentHelpBlock">
              <label class="form-check-label" for="useCreditForEnrollment">
                Use Available Credit for Enrolling
              </label>
              <small
                  id="useCreditForEnrollmentHelpBlock"
                  class="form-text text-muted">When you have a credit you can choose to directly use that credit to enroll
                days, if there is enough credit remaining for the maximum cost of that days options.</small>

            </div>
          </div>
        </div>
      </div>
      <div v-for="student in students"
           class="col mb-4"
           :key="`month_notice_${student.id}`">
        <div class="card" :class="classForStudent(student)">
          <div class="card-body"><strong>{{ student.name }}</strong> Enrolled in {{ enrolledDays(student) }} of
            {{ availableDays }} days.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { calendarMixin } from '@/components/mixins/calendarMixin'
import { isEmpty } from 'lodash/core'
import { SIZE_ALERT, SIZE_WARNING } from '@/constants/application'
import { extendMoment } from 'moment-range'
import Moment from 'moment'
import { mapGetters } from 'vuex'
import { dayOfWeekInBitmask } from '@/utilities/bitmaskMaps'

const moment = extendMoment(Moment)

export default {
  name: 'MonthNotice',
  mixins: [calendarMixin],
  props: {
    month: {
      type: [Number, String],
      default: 0
    },
    weeks: {
      type: Object,
      required: true
    },
    calendar: {
      type: Object,
      required: true
    },
    students: {
      type: Array,
      default: () => []
    },
    year: {
      type: [Number, String],
      required: true
    },
    enableEnrollManyDays: {
      type: Boolean,
      required: true
    },
    hasCredit: {
      type: Boolean,
      required: true
    },
    useCreditForEnrollment: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      enrolledStudents: {},
      showOptionHelpInfo: false
    }
  },
  computed: {
    ...mapGetters({
      isManager: 'gteManager',
      registration: 'registration'
    }),
    enableEnrollManyDaysToggle: {
      // getter
      get: function () {
        return this.enableEnrollManyDays
      },
      // setter
      set: function (newValue) {
        this.$emit('update:enable-enroll-many-days', newValue)
      }
    },
    useCreditForEnrollmentToggle: {
      // getter
      get: function () {
        return this.useCreditForEnrollment
      },
      // setter
      set: function (newValue) {
        this.$emit('update:use-credit-for-enrollment', newValue)
      }
    },
    days () {
      const weekArray = Object.values(this.weeks)
      const days = []
      weekArray.forEach(week => {
        const weekDays = Object.values(week)
        weekDays.forEach(day => {
          days.push(day)
        })
      })
      return days
    },
    availableDays () {
      let count = 0
      this.days.forEach(day => {
        let dayType = 'Normal Day'
        if (typeof day.special_day !== 'undefined') {
          dayType = day.special_day.name
        }
        if (dayType !== 'No Programing' && this.isDayEnrolled(day) &&
            (this.isManager ||
                moment(day.date).isBetween(this.calendar.signup_start_date, this.calendar.signup_end_date, 'day', '[]'))
        ) {
          count++
        }
      })
      return count
    }
  },
  methods: {
    classForStudent (student) {
      const theClass = []
      if (this.unenrolledDays(student) > SIZE_ALERT) {
        theClass.push('bg-danger-light')
        theClass.push('border-danger')
      } else if (this.unenrolledDays(student) > SIZE_WARNING) {
        theClass.push('bg-warning-light')
        theClass.push('border-warning')
      } else {
        theClass.push('bg-success-light')
        theClass.push('bborder-light')
      }
      return theClass
    },
    enrolledDays (student) {
      let count = 0
      this.days.forEach(day => {
        if (!isEmpty(day.course)) {
          const courses = this.coursesForDay(day.date)
          // console.log('enrolledDays', student.first, day.date, courses)
          const course = courses.find(course => {
            if (!isEmpty(course.students)) {
              const foundStudent = course.students.find(courseStudent => {
                return courseStudent.student_id === student.id
              })
              return !isEmpty(foundStudent)
            }
            return false
          })
          if (!isEmpty(course)) {
            count++
          }
        }
      })
      return count
    },
    unenrolledDays (student) {
      return this.availableDays - this.enrolledDays(student)
    },
    coursesForDay (date) {
      const courses = this.calendar.courses
      if (isEmpty(courses)) {
        return []
      }
      const day = moment(date)
      return courses.filter(function (course) {
        const courseStart = moment(course.startDate)
        return courseStart.isSame(day, 'day')
      })
    },
    enrolledCourseStudent (course, student) {
      if (
        typeof course.students !== 'undefined' &&
          course.students.length > 0
      ) {
        const inCourse = course.students.find(function (checkStudent) {
          return checkStudent.id === student.id
        })
        if (typeof inCourse !== 'undefined') {
          return inCourse.enrolled_course
        }
      }
      if (!isEmpty(course.calendar_id) && !isEmpty(this.calendar.courses)) {
        const templateId = course.id
        const day = this.day.date
        const courseForTemplate = this.calendar.courses.find(function (course) {
          const startDate = moment(course.startDate)
          return (
            parseInt(course.template_id) === parseInt(templateId) &&
              startDate.isSame(day, 'day')
          )
        })
        if (!isEmpty(courseForTemplate)) {
          const enrolledCourse = courseForTemplate.students.find(function (
            templateStudent
          ) {
            return (
              parseInt(student.id) === parseInt(templateStudent.student_id)
            )
          })
          if (!isEmpty(enrolledCourse)) {
            return enrolledCourse
          }
        }
      }
      return null
    },
    isDayEnrolled (day) {
      const specialDay = day.special_day
      if (
        typeof specialDay !== 'undefined' &&
          typeof specialDay.type !== 'undefined' &&
          specialDay.type === 'no_programing'
      ) {
        return true // no need to check enrolled for no_programming dayss
      }
      if (this.calendar.only_in_course) {
        const enrolled = this.enrolled(day)
        if (isEmpty(enrolled)) {
          return false
        } else {
          return true
        }
      }
      return true
    },
    enrolled (day) {
      const date = moment(day.date)
      const timestamp = date.unix()
      // const test = this.enrolledStudents[timestamp]
      if (isEmpty(this.enrolledStudents) || isEmpty(this.enrolledStudents[timestamp])) {
        const dayOfWeek = date.day()
        const calendar = this.calendar
        if (calendar.only_in_course) {
          const courses = calendar.credit_overlap_students
          const students = {}
          for (const studentID in courses) {
            if (Object.prototype.hasOwnProperty.call(courses, studentID)) {
              const studentCourses = courses[studentID]
              if (!isEmpty(studentCourses) && studentCourses.length > 0) {
                const enrolled = studentCourses.filter(course => {
                  if (!isEmpty(course.enrolled_course)) {
                    const days = course.enrolled_course.day_bit
                    if (dayOfWeekInBitmask(dayOfWeek, days)) {
                      return true
                    }
                  }
                })
                if (!isEmpty(enrolled)) {
                  students[studentID] = enrolled
                }
              }
            }
          }
          this.enrolledStudents[timestamp] = students
        }
      }
      return this.enrolledStudents[timestamp]
    }
  }
}
</script>

<style scoped>

</style>

import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ENROLLED_COURSES,
  UPDATE_ENROLLMENT,
  UPDATE_CALENDAR
} from '@/constants/mutation-types'
import { API, MANAGER_API } from '@/utilities/http-common'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { isEmpty } from 'lodash/core'
const moment = extendMoment(Moment)
moment.tz.setDefault('America/Phoenix')

export const calendarMixin = {
  computed: {
    ...mapGetters({
      member: 'registrationMember',
      registration: 'registration',
      enrolledCourses: 'enrolledCourses',
      program: 'program'
    })

  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES,
      updateCalendar: UPDATE_CALENDAR,
      updateEnrollment: UPDATE_ENROLLMENT
    }),
    /**
     *
     * @param student
     * @param date
     * @param template
     * @param start
     * @param end
     * @returns {Promise<any>}
     */
    addDayToCart (student, date, template, start, end) {
      const days = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        member_id: this.member.id,
        days: [
          {
            student_id: student.id,
            date: date,
            template_id: template.id,
            start: start,
            end: end,
            first: student.first,
            name: student.name
          }
        ],
        start: start,
        end: end
      }
      const url =
        '/programs/' + this.program.slug + '/enrolled_courses/add_calendar'
      return new Promise((resolve, reject) => {
        API.put(url, days)
          .then(response => {
            const data = response.data
            if (typeof data !== 'undefined') {
              if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === false
              ) {
                this.updateEnrolledCourses(data.enrolled_courses)
                this.$emit('added-to-cart', {
                  student: this.student,
                  course: this.template,
                  date: date,
                  start: start,
                  end: end
                })
                resolve(data)
              } else if (data.result.isError === true) {
                reject(data)
              }
            } else {
              reject(response)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    enrollDay (student, member, date, template, start, end) {
      const days = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        member_id: member.id,
        allow_over_enroll: this.allowOverEnroll,
        days: [
          {
            student_id: student.id,
            date: date,
            template_id: template.id,
            start: start,
            end: end,
            first: student.first,
            name: student.name,
            site_calendar_id: this.calendar.id,
            allow_over_enroll: this.allowOverEnroll
          }
        ]
      }
      const url = '/enrolled_courses/enroll_calendar'
      const promise = new Promise((resolve, reject) => {
        MANAGER_API.put(url, days)
          .then(response => {
            const data = response.data
            if (typeof data !== 'undefined') {
              if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === false
              ) {
                console.log('calendar.enrollDay', data)
                if (!isEmpty(data.full_courses)) {
                  data.full_courses.forEach(fullCourse => {
                    this.$notify({
                      group: 'enrollment',
                      type: 'warn',
                      title: template.name,
                      text:
                        student.name +
                        ' was not signed up for ' +
                        template.name +
                        ' it was full.  Turn on Force Over Enrollment to sign up',
                      duration: 2000,
                      speed: 1000
                    })
                  })
                  this.$emit('enrolled-course', {
                    student: student,
                    course: template,
                    date: date,
                    start: start,
                    end: end
                  })
                  this.updateEnrollment()
                  reject(data)
                } else if (!isEmpty(data.failed_courses)) {
                  data.failed_courses.forEach(failedCourse => {
                    this.$notify({
                      group: 'enrollment',
                      type: 'error',
                      title: failedCourse.name,
                      text:
                        student.name +
                        ' was not signed up for ' +
                        failedCourse.name,
                      duration: 2000,
                      speed: 1000
                    })
                  })
                  this.updateEnrollment()
                  reject(data)
                } else if (!isEmpty(data.enrolled_courses)) {
                  data.enrolled_courses.forEach(enrolledCourse => {
                    this.$notify({
                      group: 'enrollment',
                      type: 'success',
                      title: enrolledCourse.name,
                      text:
                      student.name +
                      ' was signed up for ' +
                      enrolledCourse.name +
                      ' on ' +
                      moment(date).format('L'),
                      duration: 2000,
                      speed: 1000
                    })
                  })
                  this.updateEnrollment()
                  resolve(data)
                }
              } else if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === true
              ) {
                this.updateEnrollment()
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: template.name,
                  text: data.result.message,
                  duration: 2000,
                  speed: 1000
                })
                reject(data)
              }
            } else {
              this.updateEnrollment()
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text:
                  'unknown error has occurred trying to enroll ' +
                  student.name +
                  ' in ' +
                  template.name +
                  ' on ' +
                  moment(date).format('L'),
                duration: 2000,
                speed: 1000
              })
              reject(response)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text:
                'unknown error has occurred trying to enroll ' +
                student.name +
                ' in ' +
                template.name +
                ' on ' +
                moment(date).format('L'),
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
      console.log('promise', promise)
      return promise
    },
    daysCanEnroll: function (date, course, student) {
      console.group('daysCanEnroll', date, course, student)
      const year = this.calendar.days[date.year()]
      const weeks = year[date.month() + 1] // zero based
      const week = weeks[date.isoWeek()]
      const day = week[date.date()]
      // find all days of this type
      const days = []
      const yearArray = Object.values(this.calendar.days)
      const dayType = this.dayType(day)
      yearArray.forEach(months => {
        const monthsArray = Object.values(months)
        monthsArray.forEach(weeks => {
          const weekArray = Object.values(weeks)
          weekArray.forEach(week => {
            const dayArray = Object.values(week)
            dayArray.forEach(day => {
              console.log('day', day, day.date, this.dayType(day))
              if ('course' in day) {
                const templateArray = Object.values(day.course)
                console.log('templateArray', templateArray, this.dayType(day))
                let hasStudent = false
                templateArray.forEach(template => {
                  console.log('template', template.students, template.students.length, this.dayType(day), template.template_id, course.id, parseInt(template.template_id) === parseInt(course.id))
                  if (template.students.length > 0 && parseInt(template.template_id) === parseInt(course.id)) {
                    // look for student
                    const enrollment = template.students.find(enrolledCourse => {
                      console.log('finding', enrolledCourse, student)
                      return enrolledCourse.student_id === student.id
                    })

                    if (enrollment !== null) {
                      console.log('foundStudent', enrollment, template)
                      hasStudent = true
                    }
                  }
                })
                if (dayType === this.dayType(day) && !hasStudent) {
                  if (!this.inCalendarCart(student, course, day.date)) {
                    days.push(day)
                  }
                }
              } else {
                if (dayType === this.dayType(day)) {
                  console.log('dayHasNoCourses', day)
                  days.push(day)
                }
              }
            })
          })
        })
      })
      console.log('days', days)
      console.warn('Day Count', days.length)
      console.groupEnd()
      return days
    },
    inCalendarCart (student, course, date) {
      if (!isEmpty(this.enrolledCourses.calendars) &&
        !isEmpty(this.enrolledCourses.calendars[course.site_calendar_id])) {
        const calendar = this.enrolledCourses.calendars[course.site_calendar_id]
        if (!isEmpty(calendar[student.id])) {
          const studentEnrollments = Object.values(calendar[student.id])
          const found = studentEnrollments.find(enrolledCourse => {
            return parseInt(course.id) === parseInt(enrolledCourse.template_id) && moment(enrolledCourse.date).isSame(date)
          })
          return !isEmpty(found)
        }
      }
      return false
    },
    dayType: function (day) {
      if (typeof day.special_day === 'undefined') {
        return 'Regular'
      } else {
        return day.special_day.name
      }
    },
    isCourseHourly (course) {
      return course.hourly_fee > 0 && (course.allow_start || course.allow_end)
    },
    maxTemplateCost (template) {
      let maxCost = 0
      if (this.isCourseHourly(template)) {
        const startTemplate = moment(template.startTime)
        const endTemplate = moment(template.endTime)
        let difference = endTemplate.diff(startTemplate, 'minutes') / 60
        const dayType = this.dayType(this.day)
        if (template.allow_start && template.allow_end) {
          // full day
        } else {
          let startTime = startTemplate
          let endTime = endTemplate
          if (template.allow_start) {
            // morning
            switch (dayType) {
              case 'Regular':
              case 'Early Release':
                endTime = moment(this.calendar.start_time)
                break
              case 'Half Day':
                endTime = moment(this.calendar.half_day_start)
                break
              case 'Late Start':
                endTime = moment(this.calendar.late_start)
            }
            difference = endTime.diff(startTemplate, 'minutes') / 60
          } else if (template.allow_end) {
            switch (dayType) {
              case 'Regular':
              case 'Late Start':
                startTime = moment(this.calendar.end_time)
                break
              case 'Early Release':
                startTime = moment(this.calendar.early_release)
                break
              case 'Half Day':
                startTime = moment(this.calendar.half_day_end)
                break
            }
            difference = endTemplate.diff(startTime, 'minutes') / 60
          }
        }
        maxCost = template.hourly_fee * difference + template.materialFee
      } else {
        // check template fees against amountOfCredit
        maxCost = template.fee + template.materialFee
      }
      return maxCost
    }
  }
}

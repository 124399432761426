<template>
  <div class="card border-success bg-success-light"
       :class="{
    'border-success bg-success-light':isManager,
    'border-info bg-info-light':!isManager
  }">
    <h3 v-if="students.length > 1" class="card-header text-light"
        :class="{
    'bg-success':isManager,
    'bg-info':!isManager
  }">
      <cs-button type="warning" icon-left="close" @handle-click="$emit('close')">
        CLOSE
      </cs-button>
      You may be able to <strong>{{ isManager ? 'enroll' : 'add to cart' }}</strong>
      {{ students.length }} {{ plural('student',students) }} in {{ dayCount }} time
      {{ plural('slot', dayCount) }} </h3>
    <h3 v-else class="card-header text-light bg-success"
        :class="{
    'bg-success':isManager,
    'bg-info':!isManager
  }">You may be able to <strong>{{ isManager ? 'enroll ' + students[0].first : 'add ' + students[0].first + ' to cart' }}</strong> in {{
        dayCount
      }} time {{ plural('slot', dayCount) }} </h3>
    <div class="card-body">
      <p class="card-title">
        Sign up in <span v-html="course.name"/>
        <template v-if="course.allow_start || course.allow_end"> from <strong>{{ timeRange(start,end) }}</strong>
        </template>
        for all below options
      </p>
    </div>
    <div class="list-group list-group-flush">
      <div
          v-for="studentDays in groupedByStudent"
          :key="studentDays.student.id"
          class="list-group-item">
        <h4 v-if="students.length > 1">{{ studentDays.student.name }} {{ studentDays.days.length }}
          {{ plural('day',studentDays.days.length) }}</h4>
        <div class="row row-cols-5 row-cols-md-4 row-cols-sm-3 row-cols-sm-2">
          <cs-button
              v-for="day in studentDays.days"
              :type="isManager?'success':'info'"
              :icon-left="isManager?'enroll':'add-cart'"
              class="col m-2"
              :loading="$wait.is(`enroll.${day.date}.${studentDays.student.id}`)"
              :key="`enroll.${day.date}.${studentDays.student.id}`"
              @handle-click="enrollDayForStudent(studentDays.student, day)"
          >{{ dateString(day.date) }}
          </cs-button>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-between">
        <cs-button type="warning" icon-left="cancel" @handle-click="$emit('close')">
          Close
        </cs-button>
        <cs-button :type="isManager?'success':'info'"
                 :icon-left="isManager?'enroll':'add-cart'"
                 :loading="$wait.is('enroll-many')"
                 @handle-click="enrollDays">
        {{ isManager?'Enroll All':'Add All to Cart' }}
      </cs-button>
    </div>
  </div>
</template>

<script>
import { calendarMixin } from '@/components/mixins/calendarMixin'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash/core'
import { dayOfWeekInBitmask } from '@/utilities/bitmaskMaps'
import { plural } from '@/utilities/stringFormatters'
import { dateString, dateTimeString, dateTimeRange, timeRange, timeString } from '@/utilities/dateFormatters'

const moment = extendMoment(Moment)

export default {
  name: 'EnrollManyDays',
  mixins: [calendarMixin],
  props: {
    student: {},
    course: {
      type: Object,
      required: true
    },
    start: {
      type: String,
      required: false,
      default: ''
    },
    end: {
      type: String,
      required: false,
      default: ''
    },
    date: {
      type: String,
      required: true
    },
    students: {
      type: Array,
      required: true
    },
    calendar: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      enrolledStudents: { }
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      member: 'registrationMember',
      isManager: 'gteManager'
    }),
    dayCount () {
      return this.days.length
    },
    /**
     *
     * @returns {*[]}
     */
    days () {
      let days = []
      this.students.forEach(student => {
        // test if isEnrolled and in the range of enrollable
        days = days.concat(this.daysCanEnroll(moment(this.date), this.course, student))
      })
      // fixme(scd) this should be sorted
      if (days.length > 0) {
        days = days.sort((a, b) => {
          return moment(a.date).diff(b.date)
        }).filter(day => {
          console.log('days', day, this.isDayEnrolled(day))
          return this.isDayEnrolled(day)
        })
      }
      if (!this.isManager) {
        // filter for signup-able days
        days = days.filter(day => {
          return moment(day.date).isBetween(this.calendar.signup_start_date, this.calendar.signup_end_date, 'day', '[]')
        })
      }
      return days
    },
    groupedByStudent () {
      return this.students.map(student => {
        let days = this.daysCanEnroll(moment(this.date), this.course, student)
          .filter(day => {
            console.log('groupedByStudent', day, this.isDayEnrolled(day))
            return this.isDayEnrolled(day)
          })
        if (days.length > 0) {
          days.sort((a, b) => {
            return moment(a.date).diff(b.date)
          })
        }
        if (!this.isManager) {
          // filter for signup-able days
          days = days.filter(day => {
            return moment(day.date).isBetween(this.calendar.signup_start_date, this.calendar.signup_end_date, 'day', '[]')
          })
        }
        return {
          student: student,
          days: days
        }
      })
    }

  },
  methods: {
    plural,
    dateString,
    dateTimeString,
    dateTimeRange,
    timeRange,
    timeString,
    isDayEnrolled (day) {
      const specialDay = day.special_day
      if (
        typeof specialDay !== 'undefined' &&
          typeof specialDay.type !== 'undefined' &&
          specialDay.type === 'no_programing'
      ) {
        return true // no need to check enrolled for no_programming dayss
      }
      if (this.calendar.only_in_course) {
        const enrolled = this.enrolled(day)
        console.log('isDayEnrolled', day, enrolled)
        if (isEmpty(enrolled)) {
          return false
        } else {
          return true
        }
      }
      return true
    },
    enrolled (day) {
      const date = moment(day.date)
      const timestamp = date.unix()
      const test = this.enrolledStudents[timestamp]
      if (isEmpty(test)) {
        const dayOfWeek = date.day()
        const calendar = this.calendar
        if (calendar.only_in_course) {
          const courses = calendar.credit_overlap_students
          const students = {}
          for (const studentID in courses) {
            if (Object.prototype.hasOwnProperty.call(courses, studentID)) {
              const studentCourses = courses[studentID]
              if (!isEmpty(studentCourses) && studentCourses.length > 0) {
                const enrolled = studentCourses.filter(course => {
                  if (!isEmpty(course.enrolled_course)) {
                    const days = course.enrolled_course.day_bit
                    if (dayOfWeekInBitmask(dayOfWeek, days)) {
                      return true
                    }
                  }
                })
                if (!isEmpty(enrolled)) {
                  students[studentID] = enrolled
                }
              }
            }
          }
          this.enrolledStudents[timestamp] = students
        }
      }
      return this.enrolledStudents[timestamp]
    },
    enrollDayForStudent (student, day) {
      this.$wait.start(`enroll.${day.date}.${student.id}`)
      if (this.isManager) {
        // enroll
        this.enrollDay(student, this.member, day.date, this.course, this.start, this.end)
          .then((response) => {
            console.log('EnrollManyDays.enrollDayForStudent', response)
            this.$wait.end(`enroll.${day.date}.${student.id}`)
          })
          .catch(e => {
            console.error('EnrollManyDays.enrollDayForStudent', e)
            this.$wait.end(`enroll.${day.date}.${student.id}`)
          })
      } else {
        // add to cart
        this.addDayToCart(student, day.date, this.course, this.start, this.end)
          .then((response) => {
            console.log('EnrollManyDays.enrollDayForStudent', response)
            this.$wait.end(`enroll.${day.date}.${student.id}`)
          })
          .catch(e => {
            console.error('EnrollManyDays.enrollDayForStudent', e)
            this.$wait.end(`enroll.${day.date}.${student.id}`)
          })
      }
      console.log('enrollDay', student, day)
    },
    enrollDays () {
      this.groupedByStudent.forEach(groupedStudent => {
        const student = groupedStudent.student
        const days = groupedStudent.days
        days.forEach(day => {
          this.enrollDayForStudent(student, day)
        })
      })
      console.log('enrollDays')
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <template v-for="template in templates">
            <template v-if="showTemplate(template)">
                <template v-if="spaceFull(template)">
                    <div class="list-group-item list-group-item-danger p-2"
                         :key="'day-' + day.date + '-template-' + template.id + '-space'">
                        <cs-icon icon="closed"/>
                        There is no space in <strong>{{ template.name }}</strong> on
                        {{ dateString(day.date) }}. <span v-if="isManager">Managers can force enroll full days.</span>
                    </div>
                    <day-enroll-buttons
                            v-if="isManager"
                            :show-enroll="showEnroll(template)"
                            :course="template"
                            :day="day"
                            :calendar="calendar"
                            :students="students"
                            :enrolled="enrolled"
                            :overlap_courses="overlapCourses"
                            :signup-closed="signupClosed"
                            :is-full="spaceFull(template)"
                            class="list-group-item list-group-item-primary p-0"
                            :key="'day-' + day.date + '-template-' + template.id + '-buttons'"
                            @added-to-cart="addedToCart"
                            @enrolled-course="enrolledCourse"
                    />
                </template>
                <template v-else>
                    <template v-if="template.public || isManager">
                        <div
                                class="list-group-item list-group-item-danger p-2"
                                v-if="spaceDanger(template)"
                                :key="'day-' + day.date + '-template-' + template.id + '-space'"
                        >
                            <cs-icon icon="warning"/>
                            <strong> {{ template.name }}</strong> on
                            {{ dateString(day.date) }} {{ spaceActualFull(template)?'is Full':'is close to filling'}}
                        </div>
                        <div
                                class="list-group-item list-group-item-warning p-2"
                                v-else-if="spaceWarning(template)"
                                :key="'day-' + day.date + '-template-' + template.id + '-space'"
                        >
                            <cs-icon icon="warning"/>
                            <strong> {{ template.name }}</strong> on
                            {{ dateString(day.date) }} is close to filling
                        </div>
                        <day-enroll-buttons
                                :show-enroll="showEnroll(template)"
                                :course="template"
                                :day="day"
                                :calendar="calendar"
                                :students="students"
                                :enrolled="enrolled"
                                :overlap_courses="overlapCourses"
                                :signup-closed="signupClosed"
                                class="list-group-item list-group-item-primary p-0"
                                :key="'day-' + day.date + '-template-' + template.id + '-buttons'"
                                @added-to-cart="addedToCart"
                                @enrolled-course="enrolledCourse"
                        />
                    </template>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { isEmpty } from 'lodash/core'
import { mapGetters, mapMutations } from 'vuex'
import { SIZE_ALERT, SIZE_WARNING } from '@/constants/application'

import DayEnrollButtons from '@/components/calendars_sessions/calendars/days/DayEnrollButtons'
import { calendarMixin } from '@/components/mixins/calendarMixin'
import { ADD_TO_REGISTRATION_BALANCE } from '@/constants/mutation-types'
import { dateString } from '@/utilities/dateFormatters'

const moment = extendMoment(Moment)
export default {
  name: 'DayEnroll',
  components: {
    DayEnrollButtons
  },
  mixins: [calendarMixin],
  props: {
    templates: {
      type: Array,
      required: true
    },
    students: {
      type: Array,
      required: true
    },
    day: {
      type: Object,
      required: true
    },
    calendar: {
      type: Object,
      required: true
    },
    overlapCourses: {
      type: Object,
      required: false
    },
    canUseCreditForEnrollment: {
      type: Boolean,
      required: true
    },
    enrolled: {
      type: Object,
      required: false
    },
    signupClosed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid',
      registration: 'registration',
      calendars: 'calendars',
      program: 'program',
      loggedIn: 'loggedIn',
      templateByStart: 'templateByStart',
      templateByEnd: 'templateByEnd',
      specialDayTemplates: 'specialDayTemplates',
      isManager: 'isProgramManager',
      allowOverEnroll: 'allowOverEnroll'
    })
  },
  methods: {
    ...mapMutations(
      { addToRegistrationBalance: ADD_TO_REGISTRATION_BALANCE }
    ),
    dateString,
    showEnroll (template) {
      /** @var {number} this is the amount in balance if negative there is credit to use */
      const amountOfCredit = -1 * this.registration.balance
      if (amountOfCredit > 0 && this.canUseCreditForEnrollment) {
        // figure out the most this time slot or template would cost
        const maxCost = this.maxTemplateCost(template)
        if (amountOfCredit >= maxCost) {
          // console.log('showEnroll.template', maxCost, amountOfCredit)
        } else {
          // console.warn('showEnroll.template', template, maxCost, amountOfCredit)
        }

        return amountOfCredit >= maxCost
      }
      return false
    },
    addedToCart (payload) {
      console.log('DayEnroll.addedToCart new', payload)
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      console.log('DayEnroll.enrolledCourse', payload)
      console.warn('DayEnroll.enrolledCourse', payload, this.maxTemplateCost(payload.course))
      this.addToRegistrationBalance(this.maxTemplateCost(payload.course))
      this.$emit('enrolled-course', payload)
    },
    courseForTemplate (template) {
      if (!isEmpty(this.day.course)) {
        const courses = this.day.course
        if (!isEmpty(courses[template.id])) {
          return courses[template.id]
        }
      }
      return null
    },
    showTemplate (template) {
      if (this.calendar.credit_overlap) {
        const enrolled = this.overlapCourses
        const start = moment(template.startTime)
        const end = moment(template.endTime)
        if (typeof enrolled !== 'undefined' && !isEmpty(enrolled)) {
          let show = false
          for (const studentId in enrolled) {
            if (Object.prototype.hasOwnProperty.call(enrolled, studentId)) {
              const courses = enrolled[studentId]
              if (typeof courses !== 'undefined' && courses.length > 0) {
                courses.some(course => {
                  // const day = this.day.date;
                  const startTime = moment(course.startTime)
                  const endTime = moment(course.endTime)
                  const range = moment.range(startTime, endTime)
                  show =
                    show ||
                    ((range.contains(start) || range.contains(end)) &&
                      !(range.contains(start) && range.contains(end)))
                  return show
                })
              }
            }
          }
          return show
        }
      }
      return true
    },
    spaceWarning: function (template) {
      const course = this.courseForTemplate(template)
      if (
        !isEmpty(course) &&
          this.spaceInCourse(course) > SIZE_ALERT &&
          this.spaceInCourse(course) <= SIZE_WARNING
      ) {
        return true
      } else {
        return false
      }
    },
    spaceDanger: function (template) {
      const course = this.courseForTemplate(template)
      if (!isEmpty(course) && this.spaceInCourse(course) <= SIZE_ALERT) {
        return true
      } else {
        return false
      }
    },
    spaceFull: function (template) {
      const course = this.courseForTemplate(template)
      if (!isEmpty(course) && this.spaceInCourse(course) <= 0) {
        return true
      } else {
        return false
      }
    },
    spaceActualFull: function (template) {
      const course = this.courseForTemplate(template)
      if (!isEmpty(course) && course.space <= 0) {
        return true
      } else {
        return false
      }
    },
    spaceInCourse (course) {
      if (!isEmpty(course.students)) {
        return course.space + course.students.filter(student => {
          return !student.dropped
        }).length
      }
      return course.space
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="p-0 m-0">
    <div class="card">
      <h2 class="card-header">
        <span v-html="calendar.name"></span> for
        <span v-html="program.name"></span>
        <small
          >&nbsp; Program offered from
          {{ dateRange(calendar.start_date, calendar.end_date, ' to ') }}
        </small>
      </h2>
      <div class="list-group list-group-flush">
        <div v-if="hasNotice" class="list-group-item">
          <div :class="noticeStyle"><span v-html="calendar.notice" /></div>
        </div>
        <div
          v-if="registration.closed"
          class="list-group-item list-group-item-danger"
        >
          <h3>This registration has been closed</h3>
          <p class="lead">
            Only Community Schools can add or change enrollments on this
            registration.
          </p>
        </div>
        <div
          v-if="
            parseInt(program.current_year) !== parseInt(registration.year) &&
              parseInt(registration.year) > 0
          "
          class="list-group-item list-group-item-warning"
        >
          <h3>This registration is not for current year</h3>
          <p class="lead">
            Please be aware that this registration is not for the current school
            year {{ schoolYear(program.current_year) }} it is for the
            {{ schoolYear(registration.year) }}. Please confirm you are
            intending to use this registration before adding days or classes.
          </p>
        </div>
        <div class="list-group-item  d-flex justify-content-between">
          <span>
            <strong>Scheduling:</strong>
            {{ dateTimeRange(calendar.signup_start_date,calendar.signup_end_date) }}
          </span>
          <span v-if="calendar.day_close_signup >= 0">
            <strong
              >Scheduling closes on
              {{ dayOfWeek(calendar.day_close_signup,true) }}s at
              {{ humanizeTime(calendar.time_close_signup) }}</strong
            >
          </span>
        </div>
        <div class="list-group-item  d-flex justify-content-between">
          <span>
            <strong>School Hours:</strong>
            {{ humanizeRange(calendar.start_time,calendar.end_time) }}
          </span>
          <span>
            <strong>Program Hours:</strong>
            {{ humanizeRange(calendar.open,calendar.close)
            }}
          </span>
        </div>
        <div
          class="list-group-item d-flex justify-content-between"
          v-if="hasEarlyRelease | hasLateStart | hasHalfDay"
        >
          <span v-if="hasEarlyRelease">
            <strong>Early Releases:</strong>
            {{ humanizeTime(calendar.early_release) }}
          </span>
          <span v-if="hasLateStart">
            <strong>Late Start:</strong>
            {{ humanizeTime(calendar.late_start) }}
          </span>
          <span v-if="hasHalfDay">
            <strong>Half Day:</strong>
            {{ humanizeTime(calendar.half_day_start) }} -
            {{ humanizeTime(calendar.half_day_end) }}
          </span>
        </div>
        <div v-if="calendar.verification_required" class="list-group-item">
          <strong class="text-success">
            <cs-icon icon="validated" /> Validated Students:
          </strong>
          {{ toList(validatedStudents) }}
        </div>
      </div>
    </div>
    <div class="card">
      <h3 class="card-header">
        More Information about the options
        <span class="float-right">
          <cs-disclose v-model="dayOptionDetails" />
        </span>
      </h3>
      <div v-if="dayOptionDetails">
        <div class="card-columns">
          <template v-for="template in calendar.templates">
            <div class="p-md-1" :key="template.id">
              <app-course-summary
                v-if="template.public || isManager"
                :course="template"
                :is-manager="isManager"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="hasPrePaid" class="card">
      <h3 class="card-header">
        Student Prepaid Hours
        <span class="float-right">
          <cs-disclose v-model="prepaidHoursDetails" />
        </span>
      </h3>
      <template v-if="prepaidHoursDetails">
        <app-pre-paid-billing :students="studentsPrepaid" />
      </template>
    </div>
    <div v-if="hasOverlap" class="card">
      <h3 class="card-header">
        Enrolled Courses
        <span class="float-right">
          <cs-disclose v-model="overlapDetails" />
        </span>
      </h3>
      <template v-if="overlapDetails">
        <app-overlapped-enrolled-courses :students="studentsOverlap" />
      </template>
    </div>
    <div class="card" >
        <app-month
          :year="displayYear"
          :calendar="calendar"
          :students="calendarStudents"
          @load-month="loadMonth"
        />
    </div>
  </div>
</template>

<script>

import CourseSummary from '@/components/shared/CourseSummary'
import PrePaidBilling from '@/components/shared/PrePaidBilling'
import OverlappedEnrolledCourses from '@/components/shared/OverlappedEnrolledCourses'
import Month from './Month'
import { isEmpty } from 'lodash/core'
import { mapGetters } from 'vuex'
import { toList } from '@/utilities/stringFormatters'
import { schoolYear, dateRange, dateTimeRange, humanizeTime, humanizeRange, dayOfWeek } from '@/utilities/dateFormatters'
import { isSameMinutes } from '@/utilities/utilities'
import parseISO from 'date-fns/parseISO'
import getMonth from 'date-fns/getMonth'

export default {
  name: 'Calendar',
  data () {
    return {
      dayOptionDetails: false,
      overlapDetails: false,
      prepaidHoursDetails: false,
      loading: true,
      errorMessage: '',
      error: {}
    }
  },
  components: {
    appCourseSummary: CourseSummary,
    appMonth: Month,
    appPrePaidBilling: PrePaidBilling,
    appOverlappedEnrolledCourses: OverlappedEnrolledCourses
  },
  mounted () {
    this.$nextTick(function () {
      this.$scrollTo('#calendar')
    })
  },
  computed: {
    ...mapGetters({
      registration: 'registration',
      calendars: 'calendars',
      students: 'students',
      calendar: 'selectedCalendar',
      program: 'program',
      loggedIn: 'loggedIn',
      isManager: 'gteManager'
    }),
    displayYear () {
      let year = this.calendar.year
      const endDate = parseISO(this.calendar.end_date)
      // 2023-04-13 (SCD) php month is 1 based date-fns is 0 based
      if (Number(this.calendar.display_month) <= (getMonth(endDate) + 1)) {
        year = year + 1
      }
      return year
    },
    noticeStyle () {
      return `alert alert-${this.calendar.notice_style}`
    },
    hasNotice () {
      const calendar = this.calendar
      return (
        typeof this.calendar.notice !== 'undefined' &&
          this.calendar.notice !== null &&
          calendar.notice.trim() !== ''
      )
    },
    hasEarlyRelease () {
      const calendar = this.calendar
      return !isSameMinutes(calendar.half_day_start, calendar.early_release) !== 0
    },
    hasLateStart () {
      const calendar = this.calendar
      return !isSameMinutes(calendar.half_day_start, calendar.late_start)
    },
    hasHalfDay () {
      const calendar = this.calendar
      return !isSameMinutes(calendar.half_day_start, calendar.half_day_end)
    },
    validatedStudents () {
      if (this.calendar.verification_required) {
        if (!isEmpty(this.calendar.validated_students)) {
          return this.calendar.validated_students.map(function (student) {
            return student.first
          })
        }
      }
      return []
    },
    calendarStudents () {
      // update to only contain students eligible for this calender
      let students = []
      if (this.calendar.verification_required) {
        if (!isEmpty(this.calendar.validated_students)) {
          students = this.calendar.validated_students
        }
      } else {
        students = this.students
      }
      const site = this.calendar.site
      students = students.filter(function (student) {
        return student.school === site.abbreviation
      })
      return students
    },
    hasOverlap () {
      return !isEmpty(this.calendar.credit_overlap_students)
    },
    hasPrePaid () {
      return !isEmpty(this.calendar.pre_paid_students)
    },
    studentsPrepaid () {
      if (this.hasPrePaid) {
        const courses = this.calendar.pre_paid_students
        const students = this.students.map(student => {
          student.courses = courses[student.id]
          return student
        })
        return students
      }
      return []
    },
    studentsOverlap () {
      if (this.hasPrePaid) {
        const courses = this.calendar.credit_overlap_students
        const students = this.students.map(student => {
          student.courses = courses[student.id]
          return student
        })
        return students
      }
      return []
    }
  },
  methods: {
    dayOfWeek,
    dateRange,
    dateTimeRange,
    humanizeTime,
    humanizeRange,
    toList,
    schoolYear,
    month (months) {
      const displayMonth = this.calendar.display_month
      return months[displayMonth]
    },
    prevMonth (months) {
      let displayMonth = parseInt(this.calendar.display_month) + 1
      if (parseInt(displayMonth) === 13) {
        displayMonth = 1
      }
      return months[displayMonth]
    },
    nextMonth (months) {
      let displayMonth = parseInt(this.calendar.display_month) + 1
      if (parseInt(displayMonth) === 13) {
        displayMonth = 1
      }
      return months[displayMonth]
    },
    loadMonth: function (month) {
      this.$emit('load-month', month)
    }
  }
}
</script>

<template>
  <div class="list-group list-group-flush">
    <template v-if="hasUnenrolledStudents">
      <div v-if="isManager" class="list-group-item p-1"
           :class="isFull?'list-group-item-danger':'list-group-item-info'">
        Enroll <span class="font-weight-bold"
                     v-html="course.name"/>
      </div>
      <div v-else-if="showEnroll" class="list-group-item list-group-item-primary p-1">
        Enroll in <span class="font-weight-bold"
                        v-html="course.name"/> using credit
      </div>
      <div v-else class="list-group-item list-group-item-success p-1">
        Add <span class="font-weight-bold"
                  v-html="course.name"/> to cart
      </div>
    </template>
    <div v-else class="list-group-item list-group-item-info">
      <span class="font-weight-bold" v-html="course.name"/>
    </div>
    <div v-for="student in studentSelections" :key="student.id"
         class="list-group-item select-time p-1"
         :class="listGroupClass"
    >
      <day-hourly-button
          v-if="isHourly"
          :course="course"
          :day="day"
          :student="student"
          :calendar="calendar"
          :enrolled="enrolled"
          :signup-closed="signupClosed"
          :should-enroll="showEnroll"
          :is-full="isFull"
          @added-to-cart="addedToCart"
          @enrolled-course="enrolledCourse"
      />
      <day-standard-button
          v-else
          :course="course"
          :student="student"
          :day="day"
          :enrolled="enrolled"
          :calendar="calendar"
          :is-student-enrolled="getStudentEnrolled(student)"
          :should-enroll="showEnroll"
          :is-full="isFull"
          :signup-closed="signupClosed"
          :key="'add-cart-' + course.id + '-student-' + student.id"
      @added-to-cart="addedToCart"
      @enrolled-course="enrolledCourse"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'
import DayHourlyButton from '@/components/calendars_sessions/calendars/days/DayHourlyButton'
import DayStandardButton from '@/components/calendars_sessions/calendars/days/DayStandardButton'
import { tShirtMixin } from '@/components/mixins/tShirtMixin'
import isEmpty from 'lodash/isEmpty'
// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'DayEnrollButtons',
  mixins: [courseMethods, tShirtMixin, calendarMethods],
  components: {
    DayHourlyButton,
    DayStandardButton
  },
  props: {
    course: { type: Object, required: true },
    day: { type: Object, required: false },
    calendar: { type: Object, required: false },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    overlap_courses: {
      type: Object,
      required: false
    },
    showEnroll: {
      type: Boolean,
      required: true
    },
    isFull: {
      type: Boolean,
      required: false,
      default: false
    },
    signupClosed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      studentForTShirt: null,
      courseForTShirt: null,
      showModal: false,
      studentSelections: []
    }
  },
  created () {
    this.program_slug = this.program.slug
    const students = this.studentsWhoCanEnroll
    this.studentSelections = students.map(function (student) {
      const s = {
        name: student.name,
        first: student.first,
        id: student.id,
        selectedStart: null,
        selectedEnd: null
      }
      return s
    })
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager'
    }),
    listGroupClass () {
      if (this.isFull) {
        return 'list-group-item-danger'
      } else if (this.showEnroll) {
        return 'list-group-item-info'
      }
      return 'list-group-item-success'
    },
    isHourly () {
      return this.course.hourly_fee > 0 &&
          (this.course.allow_start || this.course.allow_end)
    },
    hasUnenrolledStudents () {
      for (const student of this.students) {
        if (!this.enrolledCourseStudent(this.course, student)) {
          return true
        }
      }
      return false
    },
    studentsWhoCanEnroll: function () {
      return this.studentsWhoCanEnrollInCourse(this.course, this.students)
    }
  },
  methods: {
    isEmpty,
    // @vuese
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    addedToCart (payload) {
      console.debug('DayEnrollButtons.addedToCart', payload)
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      console.debug('DayEnrollButtons.enrolledCourse', payload)
      this.$emit('enrolled-course', payload)
    },
    didMount (e) {
      console.log('didMount', e)
    },
    getStudentEnrolled (student) {
      const templateContained = this.templateContained(student)
      const enrolledCourseStudent = this.enrolledCourseStudent(this.course, student)
      return templateContained || !isEmpty(enrolledCourseStudent)
    },
    hideModal () {
      this.showModal = false
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}

</style>

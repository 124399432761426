<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="status === 'loading'">
        <div class="alert alert-info" :key="loading">
          <h2>
            <cs-loading theme="info">
              Loading {{ titleCase(plural(program.calendar_label, 2)) }}
            </cs-loading>
          </h2>
        </div>
      </div>
      <div v-else-if="status === 'error'">
        <div class="alert alert-danger" :key="error">
          Error:
          <json-view :data="error"></json-view>
        </div>
      </div>
      <div id="calendars" v-else key="loaded">
        <div v-if="calendars.length > 0" key="calendars" class="card w-100">
          <div class="card-header">
            <h3>{{ titleCase(plural(program.calendar_label, calendars.length)) }} for <span v-html="program.name" /></h3>
          </div>
          <div class="card-body p-0 p-lg-2">
            <nav class="nav nav-pills flex-column flex-sm-row">
              <button
                v-for="calendar in calendars"
                :key="calendar.id"
                :class="{
                  'bg-success':
                    (selectedCalendar == null ||
                      calendar.id === selectedCalendar.id) &&
                    calendar.registration_open,
                  'bg-success-light':
                    calendar.registrationOpen &&
                    !(
                      selectedCalendar == null ||
                      calendar.id === selectedCalendar.id
                    ),
                  'bg-info':
                    !calendar.registrationOpen &&
                    (selectedCalendar == null ||
                      calendar.id === selectedCalendar.id),
                  'bg-info-light':
                    !calendar.registration_open &&
                    !(
                      selectedCalendar == null ||
                      calendar.id === selectedCalendar.id
                    )
                }"
                v-on:click.prevent="selectCalendar(calendar)"
                class="flex-sm-fill text-sm-center nav-link"
              >
                <span v-html="calendar.name" /><br /><span class="text-muted"
                  >{{ dateRange(calendar.start_date, calendar.end_date) }}</span
                >
              </button>
            </nav>
            <transition>
              <div v-if="loading" class="card bg-info-light">
                <h3 class="card-header">
                  <cs-loading theme="info">
                    {{ loadingMessage }}
                  </cs-loading>
                </h3>
              </div>
            </transition>
            <transition>
              <app-calendar
                id="calendar"
                v-if="selectedCalendar !== null && selectedCalendar.id !== 0"
                :loggedIn="loggedIn"
                @load-month="loadMonth"
              />
            </transition>
          </div>
        </div>
        <div v-else class="card w-100 border-warning">
          <div class="card-header bg-warning">
            <h4>No calendars available for <span v-html="program.name" /></h4>
          </div>
          <div class="card-body bg-warning-light">
            <p class="lead">
              Please check with the {{ program.admin_name }} for more
              information
              <a
                v-bind:href="'mailto:' + program.admin_email"
                class="btn btn-info"
              >
                <font-awesome-icon icon="envelope" />
                {{ program.admin_name }}</a
              >
              {{ program.phone | phone }} for more information
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Calendar from '@/components/calendars_sessions/calendars/Calendar.vue'
import {
  UPDATE_CALENDAR,
  UPDATE_CALENDARS
} from '@/constants/mutation-types'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { dateRange, dateString } from '@/utilities/dateFormatters'
import { titleCase, plural } from '@/utilities/stringFormatters'
import setMonth from 'date-fns/setMonth'

export default {
  name: 'Calendars',
  props: {
    calendarId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      status: 'loading',
      loading: false,
      loadingMessage: 'Loading Calendar',
      errorMessage: '',
      error: {}
    }
  },
  components: {
    appCalendar: Calendar
  },
  created () {
    this.$store
      .dispatch(UPDATE_CALENDARS, {})
      .then(status => {
        this.status = status
        if (this.calendars.length === 1) {
          this.selectCalendar(this.calendars[0])
        }
      })
      .catch(e => {
        this.status = 'error'
        this.errorMessage = e.message
        this.error = e
        // eslint-disable-next-line
          console.error(e)
      })
  },
  computed: {
    ...mapGetters({
      registration: 'registration',
      calendars: 'calendars',
      students: 'students',
      selectedCalendar: 'selectedCalendar',
      program: 'program',
      loggedIn: 'loggedIn'
    })
  },
  methods: {
    ...mapMutations([UPDATE_CALENDARS]),
    ...mapActions([UPDATE_CALENDAR]),
    dateRange,
    plural,
    titleCase,
    selectCalendar: function (calendar) {
      this.loading = true
      this.status = 'loading'
      this.$wait.start('loading-calendar')
      this.$emit('update:calendar-id', calendar.id)
      this.updateCalendar(calendar)
        .then(status => {
          this.status = status
          this.loading = false

          this.$wait.end('loading-calendar')
        })
        .catch(e => {
          this.status = 'error'
          this.loading = false
          this.$wait.end('loading-calendar')
          this.errorMessage = e.message
          this.error = e
          // eslint-disable-next-line
            console.error(e)
        })
    },
    loadMonth: function (month) {
      this.loading = true
      this.$wait.start('loading-calendar')
      const date = setMonth(new Date(), month - 1)
      this.loadingMessage = 'Loading ' + dateString(date, 'LLLL')
      const calendar = this.selectedCalendar
      calendar.month = month
      this.updateCalendar(calendar)
        .then(status => {
          this.status = status
          this.loading = false
          this.$wait.end('loading-calendar')
          this.loadingMessage = 'Loading Calendar'
          this.updateCalendarUrl(month)
        })
        .catch(e => {
          this.status = 'error'
          this.loading = false
          this.$wait.end('loading-calendar')
          this.errorMessage = e.message
          this.error = e
          // eslint-disable-next-line
          console.error(e)
        })
    },
    updateCalendarUrl (month) {
      const url = new URL(window.location.href)
      url.searchParams.set('select', 'calendars')
      url.searchParams.set('calendar_id', this.selectedCalendar.id)
      url.searchParams.set('month', month)
      console.log('updateCalendarUrl', month, url.href, this.selectedCalendar)
    }
  }

}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>

import { MANAGER_API } from '@/utilities/http-common'

const OVERTIME_FEE = 1
const LATE_FEE = 2
const UNCOMMON_EVENING_FEE = 3
const RETURN_CHECK_FEE = 4
const NEXT_YEAR_REGISTRATION_FEE = 5
const NO_TYPE_FEE = 11
const TRANSACTION_FEE = 13
const CANCELED_COURSE_PRO_RATE_FEE = 20
const COMMUNITY_SCHOOLS_CLASS_CREDIT = 6
const SCOUTS_CREDIT = 7
const FIELD_TRIP_CREDIT = 8
const REFUND_CREDIT = 9
const EMPLOYEE_DISCOUNT_CREDIT = 10
const MISCELLANEAOUS_CREDIT = 14
const FULL_PAYMENT_CREDIT = 16
const BIANNUAL_PAYMENT_CREDIT = 17
const SERVICES_CREDIT = 18
const SCHOLARSHIP_CREDIT = 19

const addFee = (feeObject) => {
  const data = Object.assign(feeObject)
  console.log('data', data)
  return MANAGER_API.put('fees/add', data).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.result
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}
const feeTypes =
  [
    { id: 1, name: 'Overtime Fee' },
    { id: 2, name: 'Late Fee' },
    { id: 3, name: 'Uncommon Evening Fee' },
    { id: 4, name: 'Return Check Fee' },
    { id: 5, name: 'Next Year Registration Fee' },
    { id: 11, name: 'No Type Fee' },
    { id: 13, name: 'Transaction Fee' },
    { id: 20, name: 'Canceled Course Pro Rate Fee' }
  ]
const creditTypes = [
  { id: 6, name: 'Community Schools Class Credit' },
  { id: 7, name: 'Scouts Credit' },
  { id: 8, name: 'Field Trip Credit' },
  { id: 9, name: 'Refund Credit' },
  { id: 10, name: 'Employee Discount Credit' },
  { id: 14, name: 'Miscellaneaous Credit' },
  { id: 16, name: 'Full Payment Credit' },
  { id: 17, name: 'Biannual Payment Credit' },
  { id: 18, name: 'Services Credit' },
  { id: 19, name: 'Scholarship Credit' }
]

export {
  addFee, creditTypes, feeTypes,
  OVERTIME_FEE,
  LATE_FEE,
  UNCOMMON_EVENING_FEE,
  RETURN_CHECK_FEE,
  NEXT_YEAR_REGISTRATION_FEE,
  NO_TYPE_FEE,
  TRANSACTION_FEE,
  CANCELED_COURSE_PRO_RATE_FEE,
  COMMUNITY_SCHOOLS_CLASS_CREDIT,
  SCOUTS_CREDIT,
  FIELD_TRIP_CREDIT,
  REFUND_CREDIT,
  EMPLOYEE_DISCOUNT_CREDIT,
  MISCELLANEAOUS_CREDIT,
  FULL_PAYMENT_CREDIT,
  BIANNUAL_PAYMENT_CREDIT,
  SERVICES_CREDIT,
  SCHOLARSHIP_CREDIT
}

<!--suppress JSUnfilteredForInLoop -->
<template>
  <cs-button
      v-if="inCart"
      @handle-click="removeFromCart()"
      class="btn btn-warning"
      :class="{
      disabled: disabled
    }"
      :loading="$wait.is(waitDelete)"
      icon-left="drop-cart"

  >
    {{ student.first }} {{ cartTime }}
  </cs-button>
  <span v-else-if="isEnrolled && !isDropped && !isEditing" class="btn-group btn-group-sm">
    <button class="btn btn-outline-primary disabled bg-light text-primary font-weight-bold"
            :title="student.first + ' is signed up for ' + enrolledTime"
            disabled>
      {{ student.first }} {{ enrolledTime }}
    </button>
    <button
        v-if="allowChange || isManager"
        @click.prevent="editing(!isEditing)"
        class="btn btn-primary"
        title="change selected time"
    >
      <cs-icon icon="edit"></cs-icon>
    </button>
    <button
        v-if="allowChange || isManager"
        :disabled="disabled"
        @click.prevent="dropEnrolled()"
        class="btn btn-warning"
        title="Drop selected time"
    >
      <cs-icon icon="drop"></cs-icon>
    </button>
    <cs-button
        v-if="allowChange && isManager"
        :disabled="disabled"
        :loading="$wait.is('delete.' . waitId)"
        @click.prevent="deleteEnrolled()"
        type="danger"
        icon="cancel"
        title="Unenroll selected time"
    >
    </cs-button>
  </span>
  <cs-loading v-else-if="$wait.is(waitString)">
    Signing up {{ student.first }}
  </cs-loading>
  <strong v-else-if="isFull && !(isManager && allowOverEnroll)" >
    Class is full
  </strong>
  <span v-else-if="!blockEnrollmentWithUnpaid">
    <transition name="fade">
      <div class="alert alert-warning" v-if="isEditing">
        You are currently choosing to change {{ student.first }}'s time of
        {{ enrolledTime }}
      </div>
    </transition>
    <template v-if="usePulldown">
      <div v-if="course.allow_start" :class="{ 'bg-warning p-1': !isValid }">
        <multiselect
            :close-on-select="true"
            :disabled="disabled"
            :options="dropTimes"
            :placeholder="student.first + ' Drop Off'"
            :searchable="false"
            :show-labels="false"
            :value="studentSelection.selectedStart"
            @input="setSelectedStart"
            label="time"
            track-by="time"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <div class="p-0">
              <template v-if="course.allow_start && course.allow_end">
                <strong>{{ student.first }}</strong> selected
                <strong> {{ option }}</strong>
              </template>
              <template v-else
              ><strong>{{ student.first }}</strong> {{ shouldEnroll ? 'enrolled' : 'in cart' }}
                <strong> {{ option }}</strong>
              </template>
            </div>
          </template>
        </multiselect>
      </div>
      <multiselect
          v-if="course.allow_end"
          :close-on-select="true"
          :disabled="disabled"
          :options="pickupTimes"
          :searchable="false"
          :show-labels="false"
          :value="studentSelection.selectedEnd"
          @input="setSelectedEnd"
          label="time"
          track-by="time"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <div class="p-0">
            <template v-if="course.allow_start && course.allow_end">
              <strong>{{ student.first }}</strong> selected
              <strong> {{ option }}</strong>
            </template>
            <template v-else
            ><strong>{{ student.first }}</strong> {{ shouldEnroll ? 'enrolled' : 'in cart' }}
              <strong> {{ option }}</strong>
            </template>
          </div>
        </template>
        <template slot="placeholder">{{ student.first }} Pick Up</template>
      </multiselect>
      <div v-if="!isValid" class="text-danger small">
        You need to select both a drop and a pickup time for
        {{ student.first }}
      </div>

      <button
          type="button"
          class="btn btn-warning"
          v-if="isEditing"
          @click.prevent="isEditing = false"
      >
        <font-awesome-icon icon="ban"> Cancel</font-awesome-icon>
      </button>
    </template>
    <template v-else>
      <template v-if="course.allow_start">
        <p>Drop off: {{ student.first }}</p>
        <button
            v-on:click.prevent="addCourseToCartForStudent(time, null)"
            v-for="time in selectTimes"
            :key="'drop' + student.id + time"
            class="btn btn-success btn-sm m-1"
            :class="{
            disabled: disabled
          }"
            :disabled="disabled"
        >
          {{ time }}
        </button>
      </template>
      <template v-if="course.allow_end">
        <p>Pick up: {{ student.first }}</p>
        <button
            v-on:click.prevent="addCourseToCartForStudent(null, time)"
            v-for="time in selectTimes"
            :key="'pickup' + student.id + time"
            class="btn btn-success btn-sm m-1"
            :class="{
            disabled: disabled
          }"
            :disabled="disabled"
        >
          {{ time }}
        </button>
      </template>
      <button
          type="button"
          class="btn btn-warning"
          v-if="isEditing"
          @click.prevent="isEditing = false"
      >
        <font-awesome-icon icon="ban"></font-awesome-icon>
      </button>
    </template>
  </span>
</template>

<script>
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ADD_TO_REGISTRATION_BALANCE, UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'DayHourlyButton',
  mixins: [courseMethods, calendarMethods],
  props: {
    student: { type: Object, required: true },
    course: { type: Object, required: true },
    day: { type: Object, required: false },
    calendar: { type: Object, required: false },
    enrolled: {
      type: [Array, Object],
      required: false
    },
    /**
     * if it should enroll directly or add to cart
     */
    shouldEnroll: {
      type: Boolean,
      required: false,
      default: false
    },
    isFull: {
      type: Boolean,
      required: false,
      default: false
    },
    signupClosed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      studentSelection: [],
      disabled: false,
      isEditing: false,
      waitString: 'dayEnrollHourlyButton.' + this.student.id + '.' + this.course.id + '.' + this.day.date
    }
  },
  created () {
    this.program_slug = this.program.slug
    this.studentSelection = {
      name: this.student.name,
      first: this.student.first,
      id: this.student.id,
      selectedStart: null,
      selectedEnd: null
    }
  },
  mounted () {
  },
  watch: {
    course: function () {
      this.disabled = false
      this.isEditing = false
    },
    inCart: function (inCart, wasInCart) {
      console.log('DayHourlyButton.watch.inCart', inCart, wasInCart)
      this.disabled = false
      this.isEditing = false
      if (wasInCart && !inCart) {
        this.studentSelection.selectedStart = null
        this.studentSelection.selectedEnd = null
      }
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'isProgramManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid',
      allowOverEnroll: 'allowOverEnroll'
    }),
    waitId () {
      return `day-hourly-button.${this.course.id}.${this.student.id}`
    },
    /**
     * this controls if someone who is just changing times is alowed to just
     * enroll and skip the cart
     * @return {boolean}
     */
    enrollWhenUpdating () {
      if (this.isEnrolled) {
        return true
      }
      return false
    },
    dropTimes () {
      var selectTimes = this.selectTimes.map(time => {
        return {
          time: time,
          student: this.studentSelection
        }
      })
      if (this.studentSelection.selectedEnd) {
        const selectedEnd = this.studentSelection.selectedEnd
        const index = selectTimes.findIndex(function (time) {
          return selectedEnd === time.time
        })
        return selectTimes.slice(0, index)
      }
      return selectTimes
    },
    pickupTimes () {
      var selectTimes = this.selectTimes.map(time => {
        return {
          time: time,
          student: this.studentSelection
        }
      })

      if (this.studentSelection.selectedStart) {
        const selectedEnd = this.studentSelection.selectedStart
        const index = selectTimes.findIndex(function (time) {
          return selectedEnd === time.time
        })
        return selectTimes.slice(index + 1)
      }
      return selectTimes
    },
    usePulldown: function () {
      return this.course.use_pulldown
    },
    cartTime () {
      var enrolled = this.cart
      if (!isEmpty(enrolled)) {
        const format = 'h:mma'
        if (!isEmpty(enrolled.start) && !isEmpty(enrolled.end)) {
          return (
            moment(enrolled.start).format(format) +
              '-' +
              moment(enrolled.end).format(format)
          )
        } else if (!isEmpty(enrolled.start)) {
          return moment(enrolled.start).format(format)
        } else if (!isEmpty(enrolled.end)) {
          return moment(enrolled.end).format(format)
        }
      }
      return ''
    },
    isValid () {
      if (this.course.allow_start && this.course.allow_end) {
        if (
          (isEmpty(this.studentSelection.selectedStart) &&
                !isEmpty(this.studentSelection.selectedEnd)) ||
            (!isEmpty(this.studentSelection.selectedStart) &&
                isEmpty(this.studentSelection.selectedEnd))
        ) {
          return false
        }
      }
      return true
    },
    enrolledTime () {
      var enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && !enrolled.dropped) {
        const format = 'h:mma'
        if (!isEmpty(enrolled.start) && !isEmpty(enrolled.end)) {
          return (
            moment(enrolled.start).format(format) +
              '-' +
              moment(enrolled.end).format(format)
          )
        } else if (!isEmpty(enrolled.start)) {
          return moment(enrolled.start).format(format)
        } else if (!isEmpty(enrolled.end)) {
          return moment(enrolled.end).format(format)
        }
      }
      return ''
    },
    enrolledStart () {
      var enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && !isEmpty(enrolled.start)) {
        return moment(enrolled.start)
      }
      return null
    },
    enrolledEnd () {
      var enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && !isEmpty(enrolled.end)) {
        return moment(enrolled.end)
      }
      return null
    },
    waitAdd () {
      let result = `cart.add.${this.course.id}.student.${this.student.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.data.date
      }
      return result
    },
    waitDelete () {
      let result = `cart.delete.${this.course.id}.student.${this.student.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.day.date
      }
      return result
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    ...mapMutations(
      { addToRegistrationBalance: ADD_TO_REGISTRATION_BALANCE }
    ),
    dropEnrolled () {
      this.$wait.start('drop.' + this.waitId)
      var enrolled = this.enrolled_course
      this.disabled = true
      const vm = this
      this.drop(enrolled, this.course, this.student)
        .then(function () {
          vm.$wait.end('drop.' + this.waitId)
        })
        .catch(function () {
          vm.$wait.end('drop.' + this.waitId)
        })
    },
    deleteEnrolled () {
      this.$wait.start('delete.' + this.waitId)
      var enrolled = this.enrolled_course
      this.disabled = true
      const vm = this
      this.delete(enrolled, this.course, this.student)
        .then(function () {
          vm.$wait.end('delete.' + this.waitId)
        })
        .catch(function () {
          vm.$wait.end('delete.' + this.waitId)
        })
    },
    editing (newValue) {
      if (newValue) {
        this.studentSelection.selectedStart = null
        this.studentSelection.selectedEnd = null
      }
      this.isEditing = newValue
    },
    removeFromCart () {
      this.disabled = true
      if (this.isCalendar) {
        this.$wait.start(this.waitDelete)
        const enrolledCourse = this.cartForStudentInCourse(this.student, this.course)
        console.log('DayHourlyButton.removeFromCart', enrolledCourse, this.student, this.course, this.calendar, this.registration)
        if (!isEmpty(enrolledCourse.cart_course_id)) {
          this.removeDayFromCartCourse(this.student, enrolledCourse.course, enrolledCourse.cart_course_id)
            .then(result => {
              this.$wait.end(this.waitDelete)
              const data = result.result
              if (!isNull(data.isError) && !data.isError) {
                this.$notify({
                  group: 'enrollment',
                  type: 'info',
                  title: this.course.name,
                  text: data.message
                })
                this.studentSelection.selectedStart = null
                this.studentSelection.selectedEnd = null
              } else {
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: this.course.name,
                  text: data.message
                })
              }
            })
            .catch(e => {
              this.$wait.end(this.waitDelete)
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: e.message
              })
            })
        } else {
          // todo: part of pre-> NEW_SIGNUP_CART can be removed
          this.removeDayFromCart(
            this.student,
            this.course,
            this.calendar,
            this.registration
          )
            .then(result => {
              this.$wait.end(this.waitDelete)
              const data = result.result
              if (!isNull(data.isError) && !data.isError) {
                this.$notify({
                  group: 'enrollment',
                  type: 'info',
                  title: this.course.name,
                  text: data.message
                })
                this.studentSelection.selectedStart = null
                this.studentSelection.selectedEnd = null
              } else {
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: this.course.name,
                  text: data.message
                })
              }
            })
            .catch(e => {
              this.$wait.end(this.waitDelete)
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: e.message
              })
            })
        }
      } else {
        this.removeCourseFromCart(
          this.student,
          this.course,
          this.session,
          this.program.slug,
          this.registration
        )
        this.studentSelection.selectedStart = null
        this.studentSelection.selectedEnd = null
      }
    },
    addCourseToCartForStudent (start, end) {
      this.disabled = true
      if (isNull(this.course.session_id)) {
        if (!isNull(this.course.site_calendar_id)) {
          this.$wait.start(this.waitString)
          this.addDayToCart(
            this.student,
            this.day.date,
            this.course,
            start,
            end
          )
            .then(data => {
              if (!isEmpty(data.students)) {
                const students = data.students
                this.$wait.end(this.waitString)
                for (const studentId in students) {
                  const student = students[studentId]
                  var message = ''
                  if (!isEmpty(student.courses)) {
                    const courses = student.courses
                    var courseNames = []
                    for (const courseId in courses) {
                      const course = courses[courseId]
                      const courseDay = moment(course.day).format('M/D')
                      courseNames.push(course.name + ' ' + courseDay)
                    }
                    message = courseNames.join(',')
                  }
                  this.$notify({
                    group: 'enrollment',
                    type: 'success',
                    title: student.first + ' added to cart',
                    text: message,
                    duration: 2000,
                    speed: 1000
                  })
                  this.$emit('added-to-cart',
                    {
                      course: this.course,
                      student: this.student,
                      day: this.day,
                      start: start,
                      end: end
                    })
                }
              }
            })
            .catch(() => {
              this.$wait.end(this.waitString)
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.student.name + ' problem',
                text:
                      this.student.name +
                      ' problem adding student to cart for day.',
                duration: 2000,
                speed: 1000
              })
            })
        }
      } else {
        this.addTimeToCart(this.student, start, end)
          .then(() => {
            // success
          })
          .catch(() => {
            this.disabled = false
          })
      }
    },
    enrollStudentInCourseForDay (start, end) {
      console.log('DayHourlyButton.enrollStudentInCourseForDay isManager', this.isManager, start, end, this.course)
      this.disabled = true
      this.$wait.start(this.waitString)
      if (!isNull(this.course.site_calendar_id)) {
        if (this.isManager) {
          this.managerEnrollDay(this.student, this.member, this.day.date, this.course, start, end)
            .then(data => {
              this.$emit('enrolled-course',
                {
                  course: this.course,
                  student: this.student,
                  day: this.day,
                  start: start,
                  end: end
                })
              this.$wait.end(this.waitString)
            })
            .catch(() => {
              this.studentSelection.selectedEnd = null
              this.studentSelection.selectedStart = null
              this.$wait.end(this.waitString)
            })
        } else {
          this.enrollStudentInDay(
            this.student,
            this.day.date,
            this.course,
            start,
            end
          )
            .then(data => {
              this.$emit('enrolled-course',
                {
                  course: this.course,
                  student: this.student,
                  day: this.day,
                  start: start,
                  end: end
                })
              this.$wait.end(this.waitString)
            })
            .catch(() => {
              this.studentSelection.selectedEnd = null
              this.studentSelection.selectedStart = null
              this.$wait.end(this.waitString)
            })
        }
      }
    },
    // sets the start time and can enroll/add to cart the student
    selectedStart () {
      return this.studentSelection.startSelection
    },
    setSelectedStart (selected) {
      const time = selected.time
      this.studentSelection.selectedStart = time
      this.shouldAddTimes()
    },
    setSelectedEnd (selected) {
      const time = selected.time
      this.studentSelection.selectedEnd = time
      this.shouldAddTimes()
    },
    shouldAddTimes () {
      const course = this.course
      if (course.allow_start && course.allow_end) {
        if (
          !isEmpty(this.studentSelection.selectedStart) &&
            !isEmpty(this.studentSelection.selectedEnd)
        ) {
          if (this.shouldEnroll || this.isManager || this.enrollWhenUpdating) {
            this.enrollStudentInCourseForDay(
              this.studentSelection.selectedStart,
              this.studentSelection.selectedEnd
            )
          } else {
            this.addCourseToCartForStudent(
              this.studentSelection.selectedStart,
              this.studentSelection.selectedEnd
            )
          }
        } else {
          let message = isEmpty(this.studentSelection.selectedStart)
            ? 'Select Pickup Time for '
            : 'Select Drop Off Time for '
          message = message + this.student.name
          this.$notify({
            group: 'enrollment',
            type: 'warning',
            title: this.course.name,
            text: message,
            duration: 2000,
            speed: 1000
          })
        }
      } else if (course.allow_start || course.allow_end) {
        if (this.shouldEnroll || this.isManager || this.enrollWhenUpdating) {
          this.enrollStudentInCourseForDay(
            this.studentSelection.selectedStart,
            this.studentSelection.selectedEnd
          )
        } else {
          this.addCourseToCartForStudent(
            this.studentSelection.selectedStart,
            this.studentSelection.selectedEnd
          )
        }
      }
    }
  }

}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>

<template>
  <div class="list-group list-group-flush">
    <span
      ><app-disclose
        disclosed-class="info"
        size="sm"
        un-disclosed-class="outline-info"
        v-model="showEnrolled"
      >
        <template v-slot:undisclosed>Show Classes</template>
        <template v-slot:disclosed>Hide Classes</template>
      </app-disclose></span
    >
    <transition-group tag="div">
      <template v-if="showEnrolled">
        <div
          v-for="(enrolledCourses, student_id) in enrolled"
          :key="student_id"
          class="list-group-item list-group-item-info"
        >
          <strong>{{ student(student_id).name }}</strong>
          <p
            v-for="course in enrolledCourses"
            class="small"
            :key="'student-' + student.id + '-course-' + course.id"
          >
            <span v-html="course.name"></span>:
            {{ course.startTime | moment("LT") }}-{{
              course.endTime | moment("LT")
            }}
            [{{ days(course) }}]
          </p>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
import CsDisclose from '@/components/controls/CsDisclose'

export default {
  name: 'EnrolledOverlapSummary',
  data () {
    return {
      showEnrolled: false
    }
  },
  props: {
    students: {
      type: [Object, Array],
      required: true
    },
    enrolled: {
      type: Object,
      required: true
    }
  },
  components: {
    AppDisclose: CsDisclose
  },
  methods: {
    student (id) {
      return this.students.find(student => {
        return Number(student.id) === Number(id)
      })
    },
    days: function (course) {
      return course.enrolled_course.days
    }
  }
}
</script>

<style scoped></style>

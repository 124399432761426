<template>
  <v-dialog
      v-model="showDialog"
      transition="slide-up"
      bg-transition="fade"
      :classes="{ root: 'modal', content: 'modal-dialog' }"
  >
    <div class="modal-content">

    <form class="styled" @submit.prevent="submitFee">
      <div class="modal-header">
        <slot name="header">{{ title }}</slot>
      </div>
      <div class="modal-body">
        <cs-loading v-if="$wait.is('add-fee')" >Adding Fee</cs-loading>
        <div v-if="successMessage !== ''" class="alert alert-success">{{ successMessage}}</div>
        <div v-if="errorMessage !== ''" class="alert alert-danger">{{errorMessage}}</div>
        <slot></slot>
        <div class="form-group">
          <label for="exampleFormControlSelect1">Fee Type</label>
          <select v-if="feeTypeIdEditable" class="form-control" id="exampleFormControlSelect1" v-model="formData.fee_type_id">
            <option v-for="feeType in feeTypes" :value="feeType.id" :key="feeType.id">{{feeType.name}}</option>
          </select>
          <input v-else class="form-control" type="text" :placeholder="feeTypeOption.name" readonly>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Fee Amount</label>
          <input
              v-if="amountEditable"
              type="number" class="form-control" id="exampleFormControlInput1" v-model="formData.amount">
          <input v-else class="form-control"  type="text" :placeholder="formData.amount" readonly>
        </div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Description</label>
          <textarea v-if="descriptionEditable" class="form-control"
                    id="exampleFormControlTextarea1"
                    v-model="formData.feeType"
                    rows="4"></textarea>
          <textarea v-else class="form-control"
                    v-model="formData.feeType"
                    readonly
                    rows="4"></textarea>

        </div>

      </div>
      <div class="modal-footer">
        <cs-button
            button-type="button"
            type="danger"
            icon-left="close"
            @handle-click="showDialog = false">Close
        </cs-button>
        <cs-button
            button-type="submit"
            type="success"
            icon-left="fee"
            :loading="$wait.is('add-fee')"
            :disable="!formValid"
        >
          {{ submitTitle }}
        </cs-button>
      </div>
    </form>
    </div>
  </v-dialog>
</template>
<script>
import { VDialog } from 'vuetensils/src/components'
import { addFee, feeTypes } from '@/api/feeAPI'

export default {
  name: 'CustomFee',
  components: {
    VDialog
  },
  props: {
    value: {
      type: Boolean
    },
    registrationId: {
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'Add Fee to Registration'
    },
    submitTitle: {
      type: String,
      required: false,
      default: 'Add Fee'
    },
    amount: {
      type: Number,
      required: false,
      default: 0
    },
    amountEditable: {
      type: Boolean,
      required: false,
      default: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    descriptionEditable: {
      type: Boolean,
      required: false,
      default: true
    },
    feeTypeId: {
      type: Number,
      required: false,
      default: 11
    },
    feeTypeIdEditable: {
      type: Boolean,
      required: false,
      default: true
    },
    courseId: {
      type: Number,
      required: false,
      default: null
    },
    sessionId: {
      type: Number,
      required: false,
      default: null
    },
    siteCalendarId: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      showDialog: this.value,
      formData: {
        amount: this.amount,
        feeType: this.description,
        registrationID: this.registrationId,
        fee_type_id: null,
        cs_course_id: this.courseId,
        session_id: this.sessionId,
        site_id: this.siteId,
        site_calendar_id: this.siteCalendarId
      },
      feeTypeOption: {},
      feeTypes: feeTypes,
      successMessage: '',
      errorMessage: ''
    }
  },
  created () {
    this.feeTypeOption = feeTypes.find(type => {
      return this.feeTypeId === type.id
    })
  },
  watch: {
    value (newValue) {
      this.showDialog = newValue
    },
    showDialog (newValue) {
      if (!newValue) {
        this.successMessage = ''
        this.errorMessage = ''
      }
      this.$emit('input', newValue)
    },
    feeTypeOption (newValue) {
      this.formData.fee_type_id = newValue.id
    }
  },
  computed: {
    formValid () {
      if (this.formData.amount <= 0) {
        return false
      }
      if (this.formData.fee_type_id < 1) {
        return false
      }
      return this.formData.description !== ''
    }
  },
  methods: {
    submitFee () {
      console.log('submit fee')
      if (this.formValid) {
        this.$wait.start('add-fee')
        this.successMessage = ''
        this.errorMessage = ''
        addFee(this.formData)
          .then(response => {
            this.$wait.end('add-fee')
            console.warn('submitFee', response)

            if (response.isError) {
              this.errorMessage = response.message
            } else {
              this.successMessage = response.message
              setTimeout(() => {
                this.successMessage = ''
                this.errorMessage = ''
                this.showDialog = false
              }, 2000)
            }
          })
          .catch(e => {
            this.$wait.end('add-fee')
            console.error('error', e)
            this.errorMessage = e.message
          })
      }
    }
  }
}
</script>
<style scoped>
</style>

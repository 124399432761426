<template>
  <div class="row m-auto py-2 w-100 bg-light border border-secondary">
    <transition name="fade">
      <div
          :class="{ 'col-md-2': showBulkActions, 'col-md-1': !showBulkActions }"
          class="cal-day-card p-0"
      >
        <template v-if="showBulkActions">
          <div class="list-group-flush">
            <div class="list-group-item">
              <cs-button
                  v-if="calendar.weekly_late_fee > 0"
                  @handle-click="showWeekFee = true"
                  type="success"
                  icon-left="fee"
                  class="my-auto">Add Week Fee
              </cs-button>
            </div>
          </div>
          <app-bulk-enroll v-if="false" :days="days"></app-bulk-enroll>
          <CustomFee v-model="showWeekFee"
                     :registration-id="registration.id"
                     :amount="calendar.weekly_late_fee"
                     :amount-editable="true"
                     :description="lateFeeDescription"
                     :description-editable="true"
                     :site-calendar-id="calendar.id"
                     :fee-type-id="LATE_FEE"
                     :fee-type-id-editable="false"
          >
            <template #header>
              Add Week Late Fee to Registration
            </template>
          </CustomFee>
        </template>
      </div>
    </transition>
    <div
        v-for="day in padLeftDays"
        class="col-md-2 cal-day-card p-0"
        :key="'dow-' + weekNumber + '-pad-' + day.date"
    >
      <app-other-month-day v-if="day.isPlaceholder" :day="day" :month="month"></app-other-month-day>
      <template v-else>
        <app-day
            v-if="isDayEnrolled(day)"
            :day="day"
            :calendar="calendar"
            :students="students"
            :enrolled="enrolled(day)"
            :can-use-credit-for-enrollment="canUseCreditForEnrollment"
            @added-to-cart="addedToCart"
            @enrolled-course="enrolledCourse"
        ></app-day>
      </template>
    </div>

    <div
        v-for="day in days"
        class="col-md-2 cal-day-card p-0"
        :key="'dow-' + weekNumber + 'week-' + day.date"
    >
      <app-day
          v-if="isDayEnrolled(day)"
          :day="day"
          :calendar="calendar"
          :students="students"
          :enrolled="enrolled(day)"
          :can-use-credit-for-enrollment="canUseCreditForEnrollment"
          @added-to-cart="addedToCart"
          @enrolled-course="enrolledCourse"
      ></app-day>
      <app-place-holder-day v-else :day="day"
      ><p class="lead">
        No students enrolled on this day
      </p></app-place-holder-day
      >
    </div>

    <div
        v-for="day in padRightDays"
        class="col-md-2 cal-day-card p-0"
        :key="'dow-' + weekNumber + '-pad-' + day.date"
    >
      <app-other-month-day v-if="day.isPlaceholder" :day="day" :month="month"></app-other-month-day>
      <template v-else>
        <app-day
            v-if="isDayEnrolled(day)"
            :day="day"
            :calendar="calendar"
            :students="students"
            :enrolled="enrolled(day)"
            :can-use-credit-for-enrollment="canUseCreditForEnrollment"
            @added-to-cart="addedToCart"
            @enrolled-course="enrolledCourse"
        ></app-day>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Day from '@/components/calendars_sessions/calendars/days/Day'
import BulkEnroll from '@/components/calendars_sessions/calendars/BulkEnroll'
import OtherMonthDay from '@/components/calendars_sessions/calendars/days/OtherMonthDay'
import PlaceHolderDay from '@/components/calendars_sessions/calendars/days/PlaceHolderDay'
import keys from 'lodash/keys'
import { isEmpty } from 'lodash/core'
import { dayOfWeekInBitmask } from '@/utilities/bitmaskMaps'
import CustomFee from '@/components/calendars_sessions/calendars/CustomFee'
import { LATE_FEE } from '@/api/feeAPI'
import parseISO from 'date-fns/parseISO'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'
import getDay from 'date-fns/getDay'
import nextFriday from 'date-fns/nextFriday'
import previousMonday from 'date-fns/previousMonday'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import format from 'date-fns/format'
import getUnixTime from 'date-fns/getUnixTime'
import { dateString } from '@/utilities/dateFormatters'

export default {
  name: 'Week',
  components: {
    CustomFee,
    appDay: Day,
    AppBulkEnroll: BulkEnroll,
    AppOtherMonthDay: OtherMonthDay,
    AppPlaceHolderDay: PlaceHolderDay
  },
  props: {
    days: {
      type: [Array, Object],
      required: true
    },
    weekNumber: {
      type: [String, Number],
      required: true
    },
    showBulkActions: {
      type: Boolean,
      required: false,
      default: false
    },
    calendar: {
      type: Object,
      required: true
    },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    month: {
      type: [Number, String],
      required: true
    },
    prevMonthWeek: {
      type: Object
    },
    nextMonthWeek: {
      type: Object
    },
    canUseCreditForEnrollment: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      enrolledStudents: {},
      showBulkEnroll: false,
      showWeekFee: false,
      LATE_FEE: LATE_FEE
    }
  },

  computed: {
    ...mapGetters({
      registration: 'registration',
      calendars: 'calendars',
      program: 'program',
      loggedIn: 'loggedIn'
    }),
    lateFeeDescription () {
      const startDate = parseISO(this.allDays[0])
      const endDate = parseISO(this.allDays[this.allDays.length - 1])
      const dateRange = dateString(startDate, 'M/d/yyyy') + ' - ' + dateString(endDate, 'M/d/yyyy')
      return this.calendar.name + ':  Late signup fee (' + dateRange + ')'
    },
    allDays () {
      const allDates = []
      if (!isEmpty(this.prevDaysForWeek)) {
        const items = Object.values(this.prevDaysForWeek)
        items.forEach(item => {
          allDates.push(parseISO(item.date))
        })
      }
      if (!isEmpty(this.days)) {
        const items = Object.values(this.days)
        items.forEach(item => {
          allDates.push(item.date)
        })
      }
      if (!isEmpty(this.nextDaysForWeek)) {
        const items = Object.values(this.nextDaysForWeek)
        items.forEach(item => {
          allDates.push(item.date)
        })
      }
      return allDates
    },
    hasPrevDaysForWeek () {
      if (!isEmpty(this.prevMonthWeek[parseInt(this.weekNumber)])) {
        return true
      } else {
        return false
      }
    },
    prevDaysForWeek () {
      if (!isEmpty(this.prevMonthWeek[parseInt(this.weekNumber)])) {
        return this.prevMonthWeek[parseInt(this.weekNumber)]
      } else {
        return {}
      }
    },
    padLeftDays () {
      const dayKeys = keys(this.days)
      const firstDay = this.days[dayKeys[0]]
      const firstDate = parseISO(firstDay.date)
      const dayOfWeek = getDay(firstDate)
      const dayArray = []
      if (dayOfWeek > 1) {
        if (this.hasPrevDaysForWeek) {
          return this.prevDaysForWeek
        }
        const monday = previousMonday(firstDate)
        const endDay = subDays(firstDate, 1)
        const days = eachDayOfInterval({
          start: monday,
          end: endDay
        })
        for (const day of days) {
          dayArray.push({
            isPlaceholder: true,
            month: format(day, 'M'),
            name: format(day, 'MMMM'),
            format: format(day, 'M/d/yy'),
            date: day
          })
        }
      }
      return dayArray
    },
    hasNextDaysForWeek () {
      if (!isEmpty(this.nextMonthWeek[parseInt(this.weekNumber)])) {
        return true
      } else {
        return false
      }
    },
    nextDaysForWeek () {
      if (!isEmpty(this.nextMonthWeek[parseInt(this.weekNumber)])) {
        return this.nextMonthWeek[parseInt(this.weekNumber)]
      } else {
        return {}
      }
    },
    padRightDays () {
      const dayKeys = keys(this.days)
      const lastDay = this.days[dayKeys[dayKeys.length - 1]]
      const lastDate = parseISO(lastDay.date)
      const dayOfWeek = getDay(lastDate)
      const dayArray = []
      if (dayOfWeek < 5) {
        if (this.hasNextDaysForWeek) {
          return this.nextDaysForWeek
        }
        const startDay = addDays(lastDate, 1)
        const friday = nextFriday(startDay)
        const days = eachDayOfInterval({
          start: startDay,
          end: friday
        })
        for (const day of days) {
          dayArray.push({
            isPlaceholder: true,
            month: format(day, 'M'),
            name: format(day, 'MMMM'),
            format: format(day, 'M/d/yy'),
            date: day
          })
        }
      }
      return dayArray
    }
  },
  methods: {
    addedToCart (payload) {
      console.log('Week.addedToCart', payload)
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      console.log('Week.enrolledCourse', payload)
      this.$emit('enrolled-course', payload)
    },
    dayType: function (day) {
      if (typeof day.special_day === 'undefined') {
        return 'Regular'
      } else {
        return day.special_day.name
      }
    },
    isDayEnrolled (day) {
      const specialDay = day.special_day
      if (
        typeof specialDay !== 'undefined' &&
          typeof specialDay.type !== 'undefined' &&
          specialDay.type === 'no_programing'
      ) {
        return true // no need to check enrolled for no_programming dayss
      }
      if (this.calendar.only_in_course) {
        const enrolled = this.enrolled(day)
        if (isEmpty(enrolled)) {
          return false
        } else {
          return true
        }
      }
      return true
    },
    enrolled (day) {
      const date = parseISO(day.date)
      const timestamp = getUnixTime(date)
      const test = this.enrolledStudents[timestamp]
      if (isEmpty(test)) {
        const dayOfWeek = getDay(date)
        const calendar = this.calendar
        if (calendar.only_in_course) {
          const courses = calendar.credit_overlap_students
          const students = {}
          for (const studentID in courses) {
            if (Object.prototype.hasOwnProperty.call(courses, studentID)) {
              const studentCourses = courses[studentID]
              if (!isEmpty(studentCourses) && studentCourses.length > 0) {
                const enrolled = studentCourses.filter(course => {
                  if (!isEmpty(course.enrolled_course)) {
                    const days = course.enrolled_course.day_bit
                    if (dayOfWeekInBitmask(dayOfWeek, days)) {
                      return true
                    }
                  }
                })
                if (!isEmpty(enrolled)) {
                  students[studentID] = enrolled
                }
              }
            }
          }
          this.enrolledStudents[timestamp] = students
        }
      }
      return this.enrolledStudents[timestamp]
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header calendar-day-header text-muted">
        {{ dateString(day.date,'M/d eeeeee') }}
    </div>
    <div class="card-body">
      <slot name="default"></slot>
      <p v-if="message !== ''" class="lead text-muted">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import { dateString } from '@/utilities/dateFormatters'
export default {
  name: 'OtherMonthDay',
  props: {
    day: {
      type: Object,
      required: true
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    dateString
  }
}
</script>

<style scoped></style>
